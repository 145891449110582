const settings = {
	arrows: true,
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	centerMode: true,
	centerPadding: '0',
	speed: 500,
	swipe: true,
	responsive: [
		{
			breakpoint: 896,
			settings: {
				slidesToShow: 1,
				centerPadding: '220px',
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				centerPadding: '180px',
			},
		},
		{
			breakpoint: 576,
			settings: {
				slidesToShow: 1,
				centerPadding: '120px',
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				centerPadding: '100px',
			},
		},
		{
			breakpoint: 384,
			settings: {
				slidesToShow: 1,
				centerPadding: '72px',
			},
		},
	],
};

export default settings;
