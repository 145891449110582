/**
 * External dependencies
 */
import { graphql, useStaticQuery } from 'gatsby';

/**
 * Internal dependencies
 */
import { findNode } from 'utils';
import { FooterDataQuery } from 'types';
import { isString } from 'utils';

const useFooterData = () => {
	const { allDataYaml }: FooterDataQuery = useStaticQuery(graphql`
		query FooterData {
			allDataYaml {
				nodes {
					footer {
						text
						url
						copyright
					}
				}
			}
		}
	`);

	const data = findNode('footer', allDataYaml);

	if (!data) {
		return;
	}

	const { copyright, text, url } = data;

	if (isString(text) && isString(url) && isString(copyright)) {
		return {
			copyright,
			text,
			url,
		};
	}
};

export default useFooterData;
