/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Box, List } from 'components';
import './styles.scss';

type SummaryProps = {
	items: Record<string, string | Array<string>>;
	otherItems: Record<string, string>;
};

const Summary: FC<SummaryProps> = ({ items, otherItems }) => (
	<Box>
		<div className="summary">
			<List
				children={Object.entries(items).map(
					([key, value], itemsIndex) => (
						<div className="summary-item" key={itemsIndex}>
							<h3 className="summary-item-title is-style-p-with-mobile">
								{key}
							</h3>
							{!Array.isArray(value) ? (
								<p className="summary-item-text indent-inside">
									{value}
								</p>
							) : (
								<ul className="indent-inside summary-list">
									{value.map((listItem, index) => (
										<li key={index}>{listItem}</li>
									))}
								</ul>
							)}
						</div>
					)
				)}
			/>
			<ul className="summary-other">
				{Object.entries(otherItems).map(([key, value], index) => (
					<li className="summary-list" key={index}>
						<span className="summary-item is-style-h3">{key}</span>
						<p className="summary-item-text">{value}</p>
					</li>
				))}
			</ul>
		</div>
	</Box>
);

export default Summary;
