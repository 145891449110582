/**
 * External dependencies
 */
import { Controller, Scene } from 'react-scrollmagic';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { Timeline, Tween } from 'react-gsap';

/**
 * Internal dependencies
 */
import './styles.scss';
import Copyright from './Copyright';
import {
	Container,
	CursorFollowerTarget,
	FullWidth,
	NavBarColorSwitcher,
} from 'components';
import { HighlightRaw } from 'components/CursorFollower/Highlight';

type FooterProps = {
	/**
	 * Copyright text displayed at the bottom. Use `%year%` placeholder to insert current year.
	 */
	copyright?: string;

	/**
	 * Animation duration in pixels (how much pixels there will be to scroll throught entire animation)
	 */
	duration?: number;

	/**
	 * Main foter text displayed in the center.
	 */
	text: string;

	/**
	 * Link URL
	 */
	url: string;
};

const Footer: FC<FooterProps> = ({
	copyright = '©%year%',
	duration = 500,
	text,
	url,
}) => {
	const [isMobile, setIsMobile] = useState<boolean | undefined>();
	const refreshToken = useRef<number>();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const callback = () => {
			if (!ref.current) {
				return;
			}

			const newIsMobile =
				parseInt(window.getComputedStyle(ref.current).minHeight) === 0;

			if (newIsMobile !== isMobile) {
				refreshToken.current = Date.now();
				setIsMobile(newIsMobile);
			}
		};

		callback();

		window.addEventListener('resize', callback);

		return () => window.removeEventListener('resize', callback);
	}, [isMobile]);

	const getFooterTextShadow = () => (
		<p className="footer-text is-shadow">{text}</p>
	);

	const sceneContent = (progress: number) => (
		<div className="footer-scene">
			<div className="footer-text-wrap">
				<div className="footer-text-inner">
					<Timeline totalProgress={progress} paused>
						<Tween
							from={{
								width: '0',
							}}
							to={{
								width: `${1038 / 14.2}%`,
							}}
						>
							<HighlightRaw />
						</Tween>
					</Timeline>

					<div>
						<Timeline totalProgress={progress} paused>
							<Tween
								from={{
									transform: `translate(-1.28em, ${
										-250 / 150
									}em)`,
								}}
								to={{ transform: 'translate(0, 0)' }}
							>
								{getFooterTextShadow()}
							</Tween>
						</Timeline>

						<Timeline totalProgress={progress} paused>
							<Tween
								from={{
									transform: `translate(${190 / 150}em, ${
										137 / 150
									}em)`,
								}}
								to={{ transform: 'translate(0, 0)' }}
							>
								{getFooterTextShadow()}
							</Tween>
						</Timeline>
					</div>

					<p className="footer-text">
						<CursorFollowerTarget>
							<Link to={url}>{text}</Link>
						</CursorFollowerTarget>
					</p>
				</div>
			</div>
			<Copyright>{copyright}</Copyright>
		</div>
	);

	return (
		<FullWidth className="footer" ref={ref}>
			<NavBarColorSwitcher color="light">
				{isMobile !== undefined && (
					<Container key={refreshToken.current}>
						<Controller>
							<Scene
								duration={duration}
								pin={!isMobile}
								triggerHook="onLeave"
							>
								{sceneContent}
							</Scene>
						</Controller>
					</Container>
				)}
			</NavBarColorSwitcher>
		</FullWidth>
	);
};

export default Footer;
