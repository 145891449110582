/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ContactInfoProps } from './types';
import * as columnsComponents from './columns';

const ContactInfo: FC<ContactInfoProps> = ({ columns }) => (
	<div className="contact-info">
		{columns.map((column, index) => (
			<div className={classnames('column', column.type)} key={index}>
				<h3>{column.title}</h3>
				{(() => {
					const { type, title, ...props } = column;
					const Component = columnsComponents[column.type];

					// @ts-ignore
					return <Component {...props} />;
				})()}
			</div>
		))}
	</div>
);

export default ContactInfo;
