/**
 * External dependencies
 */
import { createContext } from 'react';

/**
 * Internal dependencies
 */
import { Point } from 'types';

export const CursorFollowerContext = createContext<Point>({ x: 0, y: 0 });

export default CursorFollowerContext;
