/**
 * External dependencies
 */
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useStore } from 'react-redux';
import gsap from 'gsap';

/**
 * Internal dependencies
 */
import { RootState } from 'store/types';
import { setNavBarColor, useSelector } from 'store';

gsap.registerPlugin(ScrollTrigger);

type NavBarColorSwitcherProps = PropsWithChildren<{
	color: RootState['navBarColor'];
}>;

const NavBarColorSwitcher: FC<NavBarColorSwitcherProps> = ({
	children,
	color,
}) => {
	const { dispatch, getState } = useStore();
	const navBarOffset = useSelector((state) => state.navBarOffset);

	const isActive = useRef<boolean>(false);
	const previousColor = useRef<RootState['navBarColor']>();
	const ref = useRef<HTMLDivElement>(null);
	const scrollTrigger = useRef<ScrollTrigger>();

	const callback = useCallback(
		(active: boolean) => {
			if (active === isActive.current) {
				// This happens on screen resize - ScrollTrigger runs `onEnter` event again.
				return;
			}

			const { navBarColor } = getState();
			const newColor = isActive.current ? previousColor.current : color;

			if (newColor && newColor !== navBarColor) {
				dispatch(setNavBarColor(newColor));

				if (!isActive.current) {
					previousColor.current = navBarColor;
				}
			}

			isActive.current = !isActive.current;
		},
		[color, dispatch, getState]
	);

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		scrollTrigger.current = ScrollTrigger.create({
			end: `bottom ${navBarOffset}px`,
			onEnter: () => callback(true),
			onEnterBack: () => callback(true),
			onLeave: () => callback(false),
			onLeaveBack: () => callback(false),
			start: `top ${navBarOffset}px`,
			trigger: ref.current,
		});

		return () => scrollTrigger.current && scrollTrigger.current.kill();
	}, [callback, navBarOffset, ref]);

	return (
		<div className="navbar-color-switcher" ref={ref}>
			{children}
		</div>
	);
};

export default NavBarColorSwitcher;
