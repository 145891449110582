/**
 * External dependencies
 */
import { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { debounce, sample, without } from 'lodash';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Container, FullWidth, Text } from 'components';
import { isBrowser } from 'utils';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

interface WordsHeroProps {
	/**
	 * Number of moves after which the error will be displayed.
	 */
	allowedMoves?: number;

	/**
	 * Description text.
	 */
	description: string;

	/**
	 * Error message displayed in the alert.
	 */
	errorMessage: string;

	/**
	 * Lines of words.
	 */
	words: Array<string>;

	/**
	 * Error timeout in seconds. After this time the block will became active again.
	 */
	timeout: number;
}

const WordsHero: FC<WordsHeroProps> = ({
	allowedMoves = 5,
	description,
	errorMessage,
	words,
	timeout = 120,
}) => {
	/**
	 * Load expire time from `localStorage`.
	 */
	const initialExpireTime = isBrowser()
		? Number(localStorage.getItem('wordsHeroExpireTime'))
		: 0;

	/**
	 * This state is needed to prevent initial CSS transition. This is changed to true once positions of words have been
	 * set and causes an addition of an `animate` class which enables the CSS transitions.
	 */
	const [active, setActive] = useState<boolean>(false);

	/**
	 * This state contains a timestamp of when the `disabled` state should be turned off. It gets set after the shuffles
	 * count exceeds the `allowedMoves`.
	 */
	const [expireTime, setExpireTime] = useState<number>(initialExpireTime);

	/**
	 * This state gets set to `true` after the component first rendered. It's used to refresh the `useResizableMemo`
	 * factories to recalculate the proper element positions. After that, the `animate` state gets set to `true` to enable
	 * CSS transitions.
	 */
	const [initialized, setInitialized] = useState<boolean>(false);

	/**
	 * Width of content element.
	 */
	const [contentWidth, setContentWidth] = useState<number>(0);

	/**
	 * Words order used for positioning.
	 */
	const [order, setOrder] = useState([...words.keys()]);

	/**
	 * Counter for shuffles.
	 */
	const counter = useRef<number>(0);

	/**
	 * Reference to inner `div` used to calculate available width.
	 */
	const innerRef = useRef<HTMLDivElement>(null);

	/**
	 * Reference to description text element.
	 */
	const descriptionRef = useRef<HTMLDivElement>(null);

	/**
	 * Index of a last shuffled word.
	 */
	const lastActivePosition = useRef<number>();

	/**
	 * `setTimeout id stored to clear the timeout on unmount.
	 */
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

	/**
	 * Array of references for each word element.
	 */
	const refs = useMemo(
		() => Array.from(words, () => createRef<HTMLDivElement>()),
		[words]
	);

	const now = Date.now();
	const isDisabled = now < expireTime;
	const timeoutMs = timeout * 1000;

	useEffect(() => {
		/**
		 * Set initialized to `true`.
		 */
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized) {
			setTimeout(() => {
				// If initialized already, turn on CSS transitions.
				setActive(true);

				// Refresh ScrollTrigger.
				ScrollTrigger.refresh();
			}, 0);
		}
	}, [initialized]);

	useEffect(() => {
		localStorage.setItem('wordsHeroExpireTime', `${expireTime}`);
	}, [expireTime]);

	useEffect(() => {
		if (isDisabled) {
			timeoutRef.current = setTimeout(() => {
				setExpireTime(0);
				lastActivePosition.current = undefined;
			}, timeoutMs);
		}
	}, [isDisabled, timeoutMs]);

	useEffect(
		() => () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		},
		[]
	);

	/**
	 * Memoized section height recalculated on window resize from word heights.
	 */
	const height = useMemo(() => {
		let newHeight = 0;

		for (const ref of refs) {
			if (ref.current) {
				newHeight += ref.current.clientHeight;
			}
		}

		return newHeight;
		/* We don't want to update the value when refs update */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [contentWidth, initialized]);

	useEffect(() => {
		if (!innerRef.current) {
			return;
		}

		const element = innerRef.current;

		const observer = new ResizeObserver(
			debounce((entries) => {
				const windowWidth = Math.min(
					window.screen.width,
					window.innerWidth
				);

				let newContentWidth: number;

				for (const entry of entries) {
					if (entry.contentRect.width !== contentWidth) {
						newContentWidth = Math.min(
							entry.contentRect.width,
							windowWidth
						);
					}
				}

				if (newContentWidth! && newContentWidth !== contentWidth) {
					setContentWidth(newContentWidth);
				}
			}, 250)
		);

		observer.observe(element);

		return () => observer.unobserve(element);
	}, [contentWidth]);

	const isMobile = descriptionRef.current
		? window.getComputedStyle(descriptionRef.current).position === 'static'
		: false;

	const positionSetup: Array<string | [string, number]> = useMemo(
		() =>
			isMobile
				? ['center', 'left', 'right']
				: [['left', 120], ['right', 250], 'left'],
		[isMobile]
	);

	const getOffset = useMemo(
		() => (index: number, itemWidth: number) => {
			const offset = positionSetup[index % positionSetup.length];
			const align = typeof offset === 'string' ? offset : offset[0];

			let realOffset =
				((typeof offset === 'object' ? offset[1] : 0) / 1420) *
				contentWidth;

			const totalWidth = realOffset + itemWidth;

			if (totalWidth > contentWidth) {
				realOffset -= totalWidth - contentWidth;
			}

			switch (align) {
				case 'left':
					return realOffset;
				case 'right':
					return contentWidth - itemWidth - realOffset;
				case 'center':
				default:
					return (contentWidth - itemWidth) / 2 + realOffset;
			}
		},
		[contentWidth, positionSetup]
	);

	const shuffle = (index: keyof typeof words) => {
		if (!active || isDisabled) {
			return;
		}

		const orderIndex = order.findIndex((v) => v === index);

		if (lastActivePosition.current === orderIndex) {
			return;
		}

		if (counter.current === allowedMoves) {
			setExpireTime(now + timeoutMs);
			alert(errorMessage);

			counter.current = 0;

			return;
		}

		counter.current++;

		const filteredOrder = without(order, index);
		const sampleItem = sample(filteredOrder);

		const newIndex = order.findIndex((v) => v === sampleItem);

		const newOrder = [...order];

		[newOrder[orderIndex], newOrder[newIndex]] = [
			newOrder[newIndex],
			newOrder[orderIndex],
		];

		lastActivePosition.current = orderIndex;

		setOrder(newOrder);
	};

	const positions = useMemo(
		() =>
			refs.map((ref, index) => {
				if (!ref.current) {
					return { x: 0, y: 0 };
				}

				const orderIndex = order.findIndex((v) => v === index);
				const rect = ref.current.getBoundingClientRect();
				const offset = getOffset(orderIndex, rect.width);

				return { x: `${offset}px`, y: `${100 * orderIndex}%` };
			}),
		/* We don't want to update the value when refs update */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
		[getOffset, order]
	);

	const floatingWords = words.map((word, index) => (
		<p
			className="floating-word"
			key={index}
			onMouseEnter={() => shuffle(index)}
			onTouchStart={() => shuffle(index)}
			ref={refs[index]}
			style={{
				transform: `translate(${positions[index].x}, ${positions[index].y})`,
			}}
		>
			{word}
		</p>
	));

	return (
		<FullWidth
			className={classnames('words-hero', {
				animate: active,
				disabled: isDisabled,
			})}
		>
			<Container>
				<div className="words-hero-inner" ref={innerRef}>
					<div className="floating-words-wrap" style={{ height }}>
						{floatingWords}
					</div>
					<div className="description" ref={descriptionRef}>
						<Text>{description}</Text>
					</div>
				</div>
			</Container>
		</FullWidth>
	);
};

export default WordsHero;
