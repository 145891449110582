/**
 * External dependencies
 */
import { ErrorMessage } from '@hookform/error-message';
import { FC } from 'react';
import { FieldErrorsImpl } from 'react-hook-form';

/**
 * Internal dependencies
 */
import './styles.scss';

type ErrorsProps = {
	errors: FieldErrorsImpl;
	fieldName: string;
};

const Errors: FC<ErrorsProps> = ({ errors, fieldName }) => (
	<ErrorMessage
		errors={errors}
		name={fieldName}
		render={({ message }) => <p className="error">{message}</p>}
	/>
);

export default Errors;
