/**
 * External dependencies
 */
import { graphql, useStaticQuery } from 'gatsby';

/**
 * Internal dependencies
 */
import { findNode } from 'utils';
import { isArrayOfStrings, isLinkData, isLinkDataArray, isString } from 'utils';
import { NavBarDataQuery } from 'types';

const useNavBarData = () => {
	const { allDataYaml }: NavBarDataQuery = useStaticQuery(graphql`
		query NavBarData {
			allDataYaml {
				nodes {
					navBar {
						logo {
							publicURL
						}
						button {
							label
							url
						}
						backgroundLines
						menuButtons {
							label
							url
						}
						menuItems {
							label
							url
						}
					}
				}
			}
		}
	`);

	const data = findNode('navBar', allDataYaml);

	if (!data) {
		return;
	}

	const { backgroundLines, button, logo, menuButtons, menuItems } = data;

	const logoURL = logo ? logo.publicURL : undefined;

	if (
		isArrayOfStrings(backgroundLines) &&
		isLinkData(button) &&
		isLinkDataArray(menuButtons) &&
		isLinkDataArray(menuItems) &&
		isString(logoURL)
	) {
		return {
			backgroundLines,
			button,
			logoURL,
			menuButtons,
			menuItems,
		};
	}
};

export default useNavBarData;
