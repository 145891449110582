/**
 * External dependencies
 */
import { FC } from 'react';
import { CustomArrowProps } from 'react-slick';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { People } from './types';

type ExternalArrowProps = {
	people: People;
};

type ArrowProps = {
	direction: '<' | '>';
	label: string;
} & ExternalArrowProps &
	CustomArrowProps;

const Arrow: FC<ArrowProps> = ({
	direction,
	label,
	people,
	currentSlide = 0,
	slideCount,
	...props
}) => {
	let index = direction === '<' ? currentSlide + 1 : currentSlide - 1;

	if (index < 0) {
		index += people.length;
	} else if (index >= people.length) {
		index -= people.length;
	}

	return (
		<Button {...props} hoverText={people[index].name} viewportRelative>
			{label}
		</Button>
	);
};

export default Arrow;

export const PrevArrow: FC<ExternalArrowProps> = (props) => (
	<Arrow label="Previous" direction=">" {...props} />
);

export const NextArrow: FC<ExternalArrowProps> = (props) => (
	<Arrow label="Next" direction="<" {...props} />
);
