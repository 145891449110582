/**
 * External dependencies
 */
import { navigate } from 'gatsby';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FC, useState } from 'react';
import { useFormspark } from '@formspark/use-formspark';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button } from 'components';

interface DropdownProps {
	/**
	 * This is the message after submission
	 */
	confirmationMessage: string;
	/**
	 * This is formspark form id
	 */
	formId: string;
	/**
	 * This is the URL for Privacy Policy
	 */
	privacyPolicyURL: string;
	/**
	 * This is the redirection URL
	 */
	redirectURL: string;
}

interface FormInputs {
	email: string;
	name: string;
	terms: boolean;
	website: string;
}

const AuditForm: FC<DropdownProps> = ({
	confirmationMessage,
	formId,
	privacyPolicyURL,
	redirectURL,
}) => {
	const [submit, submitting] = useFormspark({
		formId,
	});

	const [visible, setVisible] = useState<boolean>(true);

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		await submit({
			email: data.email,
			name: data.name,
			website: data.website,
		});

		const fbq = (window as any).fbq;

		if (typeof fbq === `function`) {
			fbq('track', 'SubmitApplication');
		}

		setVisible(!visible);

		navigate(redirectURL);
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<FormInputs>();

	return (
		<div className="form">
			<form onSubmit={handleSubmit(onSubmit)} id="contact-form">
				<div className="form-fields">
					<div className="form-input-wrapper">
						<label htmlFor="email" className="form-input-label">
							Email
						</label>
						<input
							className="form-input"
							type="email"
							{...register('email', { required: true })}
						/>
						{errors.email && (
							<div className="error-detail">
								Provide a valid email address
							</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="name" className="form-input-label">
							First Name
						</label>
						<input
							className="form-input"
							{...register('name', { required: true })}
						/>
						{errors.name && (
							<div className="error-detail">
								Provide the first name
							</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="name" className="form-input-label">
							Website URL
						</label>
						<input
							className="form-input"
							type="url"
							{...register('website', { required: true })}
						/>
						{errors.website && (
							<div className="error-detail">
								Provide a valid address
							</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<input
							id="checkbox"
							className="form-input"
							type="checkbox"
							{...register('terms', { required: true })}
						/>
						<label
							htmlFor="checkbox"
							className="form-input-label checkbox"
						>
							<p className="terms">
								I agree to the{' '}
								<a href={privacyPolicyURL}>Privacy Policy</a>.{' '}
							</p>
						</label>
						{errors.terms && (
							<div className="error-detail">
								Confirm you agree to the Privacy Policy
							</div>
						)}
					</div>
				</div>
			</form>
			<Button
				form="contact-form"
				type="submit"
				disabled={submitting}
				className={classnames('button', {
					hidden: !visible,
					show: visible,
				})}
			>
				Apply
			</Button>
			<div
				className={classnames('message', {
					hidden: visible,
					show: !visible,
				})}
			>
				{confirmationMessage}
			</div>
		</div>
	);
};

export default AuditForm;
