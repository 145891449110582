/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as Twitter } from 'images/twitter.svg';
import { SocialMediaShare } from '../types';
import ShareButton from 'components/ShareButtons/ShareButton';

const TwitterShareButton: FC<SocialMediaShare> = ({ href }) => (
	<ShareButton
		href={`https://twitter.com/intent/tweet?url=${href}`}
		icon={<Twitter />}
	/>
);

export default TwitterShareButton;
