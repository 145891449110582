/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from 'components/ShareButtons';
import { SocialMediaShare } from 'components/ShareButtons/types';
import CopyButton from 'components/CopyButton';
import './styles.scss';

const buttonComponents = {
	facebook: FacebookShareButton,
	linkedin: LinkedinShareButton,
	twitter: TwitterShareButton,
};

type SharePostProps = {
	buttons?: Array<keyof typeof buttonComponents>;
	href: string;
	label?: string;
};

const SharePost: FC<SharePostProps> = ({
	buttons = Object.keys(buttonComponents),
	href,
	label = 'Share:',
}) => (
	<div className="share-post">
		<p className="share-post-label">{label}</p>
		<div className="share-post-buttons">
			<CopyButton href={href} />
			{buttons.map((key) => {
				const ButtonComponent: FC<SocialMediaShare> =
					buttonComponents[key as keyof typeof buttonComponents];

				return <ButtonComponent href={href} key={key} />;
			})}
		</div>
	</div>
);

export default SharePost;
