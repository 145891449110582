/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import { Box, Button } from 'components';
import './styles.scss';

type CtaProps = {
	buttonText: string;
	subtitle: string;
	title: string;
	to: string;
};

const Cta: FC<CtaProps> = ({ buttonText, subtitle, title, to }) => (
	<Box type="secondary">
		<div className="cta">
			<div className="cta-content">
				<h2 className="cta-title is-style-h2-small">{title}</h2>
				<p className="cta-subtitle is-style-p-with-mobile">
					{subtitle}
				</p>
			</div>
			<Link to={to}>
				<Button>{buttonText}</Button>
			</Link>
		</div>
	</Box>
);

export default Cta;
