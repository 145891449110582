/**
 * External dependencies
 */
import { ReactElement } from 'react';
import { RegisterOptions, useFormState } from 'react-hook-form';

/**
 * Internal dependencies
 */
import { Errors, Input } from 'components';
import './styles.scss';

type FormFieldProps<T> = {
	label?: string;
	name: T;
	validation?: RegisterOptions;
	placeholder?: string;
	className?: string;
};

const FormField = <T extends string>({
	label,
	name,
	validation,
	placeholder,
	className,
}: FormFieldProps<T>): ReactElement | null => {
	const { errors } = useFormState();

	return (
		<div className="form-field">
			{label && (
				<label className="form-field-label" htmlFor={name}>
					{label}
				</label>
			)}
			<Input
				validation={validation}
				name={name}
				placeholder={placeholder}
				className={className}
			/>
			<Errors fieldName={name} errors={errors} />
		</div>
	);
};

export default FormField;
