import { Button, HeaderHero, NavBarColorSwitcher, Spacer, Form, WrapperBody } from "components";
import * as React from 'react';
export default {
  Button,
  HeaderHero,
  NavBarColorSwitcher,
  Spacer,
  Form,
  WrapperBody,
  React
};