/**
 * External dependencies
 */
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { FC, useState } from 'react';
import { useFormspark } from '@formspark/use-formspark';
import classnames from 'classnames';
import Select from 'react-select';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button } from 'components';

interface DropdownProps {
	/**
	 * These are the select choices
	 */
	choices: Array<string>;
	/**
	 * This is the message after submission
	 */
	confirmationMessage: string;
	/**
	 * This is formspark form id
	 */
	formsparkId: string;
	/**
	 * This is the URL for Privacy Policy
	 */
	privacyPolicyURL: string;
}

interface SelectField {
	value: string;
	label: string;
}
interface FormInputs {
	email: string;
	name: string;
	message: string;
	terms: boolean;
	select: SelectField;
}

const Form: FC<DropdownProps> = ({
	choices,
	confirmationMessage,
	formsparkId,
	privacyPolicyURL,
}) => {
	const [submit, submitting] = useFormspark({
		formId: formsparkId,
	});

	const [visible, setVisible] = useState<boolean>(true);

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		await submit({
			message: data.message,
			email: data.email,
			name: data.name,
			selected: data.select.value,
		});

		const fbq = (window as any).fbq;

		if (typeof fbq === `function`) {
			fbq('track', 'SubmitApplication');
		}

		setVisible(!visible);
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm<FormInputs>();

	const selectStyles = {
		control: () => ({
			display: 'flex',
			maxWidth: 700,
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		dropdownIndicator: () => ({
			color: '#c5c5cd',
			padding: 0,
		}),
		option: () => ({
			borderBottom: '1px dotted #c5c5cd',
			padding: '5px 18px',
		}),
	};

	return (
		<div className="form">
			<form onSubmit={handleSubmit(onSubmit)} id="contact-form">
				<div className="form-fields">
					<div className="form-input-wrapper">
						<label htmlFor="email" className="form-input-label">
							Email
						</label>
						<input
							className="form-input"
							type="email"
							{...register('email', { required: true })}
						/>
						{errors.email && (
							<div className="error-detail">
								Provide a valid email address
							</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="name" className="form-input-label">
							Name
						</label>
						<input
							className="form-input"
							{...register('name', { required: true })}
						/>
						{errors.name && (
							<div className="error-detail">Provide the name</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="service" className="form-input-label">
							Service
						</label>
						<Controller
							name="select"
							control={control}
							render={({ field }) => (
								<Select
									className="form-select"
									styles={selectStyles}
									placeholder="--Choose--"
									{...register('select', { required: true })}
									{...field}
									options={choices.map((choice) => ({
										value: choice,
										label: choice,
									}))}
								/>
							)}
						/>
						{errors.select && (
							<div className="error-detail">
								Select the service
							</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<label htmlFor="textarea" className="form-input-label">
							Brief description of your project
						</label>
						<textarea
							className="form-textarea"
							{...register('message', { required: true })}
						/>
						{errors.message && (
							<div className="error-detail">
								Enter the project brief
							</div>
						)}
					</div>
					<div className="form-input-wrapper">
						<input
							id="checkbox"
							className="form-input"
							type="checkbox"
							{...register('terms', { required: true })}
						/>
						<label
							htmlFor="checkbox"
							className="form-input-label checkbox"
						>
							<p className="terms">
								I agree to the{' '}
								<a href={privacyPolicyURL}>Privacy Policy</a>.{' '}
							</p>
						</label>
						{errors.terms && (
							<div className="error-detail">
								Confirm you agree to the Privacy Policy
							</div>
						)}
					</div>
				</div>
			</form>
			<Button
				form="contact-form"
				type="submit"
				disabled={submitting}
				className={classnames('button', {
					hidden: !visible,
					show: visible,
				})}
			>
				Send
			</Button>
			<div
				className={classnames('message', {
					hidden: visible,
					show: !visible,
				})}
			>
				{confirmationMessage}
			</div>
		</div>
	);
};

export default Form;
