/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import { Button, Image, PersonInfo } from 'components';
import { Image as ImageType } from 'types';
import { PersonInfoProps } from 'components/PersonInfo';
import './styles.scss';

type ArticleSummaryProps = {
	author: Pick<PersonInfoProps, 'name' | 'role' | 'avatar'>;
	poster: ImageType;
	summary: string;
	title: string;
	url: string;
};

const ArticleSummary: FC<ArticleSummaryProps> = ({
	author: { avatar, name, role },
	poster,
	summary,
	title,
	url,
}) => (
	<div className="article-summary">
		<div className="article-summary-wrap">
			<Link to={url} className="article-summary-link">
				<h3 className="article-summary-title">{title}</h3>
			</Link>
			<PersonInfo
				avatar={avatar}
				name={name}
				role={role}
				prefix="by"
				mobileSize="small"
			/>
			<div className="article-summary-content">
				<p className="article-summary-text">{summary}</p>
				{url && (
					<Link to={url} className="article-summary-link">
						<Button>Read more</Button>
					</Link>
				)}
			</div>
		</div>
		<Link to={url} className="article-summary-link">
			<Image
				image={{
					src: poster.src,
					title: poster.title,
				}}
			/>
		</Link>
	</div>
);

export default ArticleSummary;
