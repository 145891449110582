/**
 * External dependencies
 */
import { createContext } from 'react';

/**
 * Internal dependencies
 */
import { HighlightProps } from 'components/CursorFollower/Highlight';

export const CursorFollowerHighlightContext = createContext<HighlightProps>({});

export default CursorFollowerHighlightContext;
