/**
 * External dependencies
 */
import { forwardRef, HTMLAttributes } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import classnames from 'classnames';

type WrapperBodyProps = {
	withSpace?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const WrapperBody = forwardRef<HTMLDivElement, WrapperBodyProps>(
	({ withSpace = true, children, className, ...props }, ref) => (
		<div
			className={classnames(
				'wrapper-body',
				!withSpace && 'without-space'
			)}
			ref={ref}
			{...props}
		>
			{children}
		</div>
	)
);

export default WrapperBody;
