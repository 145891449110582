/**
 * External dependencies
 */
import {
	FC,
	forwardRef,
	HTMLAttributes,
	ReactNode,
	Ref,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';

/**
 * Internal dependencies
 */
import { CursorFollower, CursorFollowerHighlight } from 'components';
import { getOffset } from 'utils';
import { HighlightProps } from './Highlight';
import { Point } from 'types';
import { useCursorFollowerPosition, useCombinedRefs } from 'hooks';

type CursorFollowerContainerProps = {
	contentRef?: Ref<HTMLDivElement>;
	follower?: ReactNode;
	highlightProps?: HighlightProps;
	ref?: Ref<HTMLDivElement>;
	refreshToken?: string;
} & HTMLAttributes<HTMLDivElement>;

const CursorFollowerContainer: FC<CursorFollowerContainerProps> = forwardRef<
	HTMLDivElement,
	CursorFollowerContainerProps
>(
	(
		{
			children,
			className,
			contentRef,
			follower,
			highlightProps,
			refreshToken,
			...props
		},
		outerRef
	) => {
		const [offset, setOffset] = useState<Point>({ x: 0, y: 0 });
		const followerPosition = useCursorFollowerPosition();
		const innerRef = useRef<HTMLDivElement>(null);
		const ref = useCombinedRefs(innerRef, outerRef);

		const maybeSetOffset = useCallback(() => {
			if (!ref.current) {
				return;
			}

			setOffset(getOffset(ref.current).offset);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [refreshToken]);

		useEffect(() => {
			maybeSetOffset();

			const debounced = debounce(maybeSetOffset, 250);

			window.addEventListener('resize', debounced);
			window.addEventListener('scroll', maybeSetOffset);

			return () => {
				window.removeEventListener('resize', debounced);
				window.addEventListener('remove', maybeSetOffset);
			};
		}, [maybeSetOffset]);

		if (!follower) {
			follower = <CursorFollowerHighlight {...highlightProps} />;
		}

		return (
			<div
				className={classnames('cursor-follower-container', className)}
				ref={ref}
				{...props}
			>
				<CursorFollower
					left={followerPosition.x - offset.x}
					top={followerPosition.y - offset.y}
				>
					{follower}
				</CursorFollower>
				<div
					className={classnames('cursor-follower-container-inner', {
						[`${className}-inner`]: !!className,
					})}
					ref={contentRef}
				>
					{children}
				</div>
			</div>
		);
	}
);

export default CursorFollowerContainer;
