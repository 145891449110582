/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';

type FlexGridProps = {
	items: Array<string>;
};

const FlexGrid: FC<FlexGridProps> = ({ items }) => (
	<ul className="flex-list">
		{items.map((item, index) => (
			<li className="flex-list-item" key={index}>
				{item}
			</li>
		))}
	</ul>
);

export default FlexGrid;
