/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import { CursorFollowerHighlightContext } from 'contexts';

const useCursorFollowerHighlightProps = () =>
	useContext(CursorFollowerHighlightContext);

export default useCursorFollowerHighlightProps;
