/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Box, PersonInfo } from 'components';
import { PersonInfoProps } from 'components/PersonInfo';
import './styles.scss';

type AuthorBoxProps = {
	author: Pick<PersonInfoProps, 'name' | 'role' | 'avatar'>;
	role?: string;
	description?: string;
};

const AuthorBox: FC<AuthorBoxProps> = ({ author, role, description }) => (
	<Box type="tertiary">
		<PersonInfo {...author} role={role} />
		{description && (
			<p className="author-box-description is-style-p-with-mobile">
				{description}
			</p>
		)}
	</Box>
);

export default AuthorBox;
