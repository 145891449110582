/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import 'styles/_index.scss';
import {
	CursorFollowerProvider,
	Loader,
	ScrollbarWidthMonitor,
} from 'components';
import { RemValueProvider } from 'contexts';
import { StoreProvider } from 'store';

const Root: FC<PropsWithChildren> = ({ children }) => (
	<StoreProvider>
		<ScrollbarWidthMonitor />
		<Loader />
		<CursorFollowerProvider>
			<RemValueProvider>{children}</RemValueProvider>
		</CursorFollowerProvider>
	</StoreProvider>
);

export default Root;
