/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import { GatsbyImage as GatsbyImageType } from 'types/images';
import './styles.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

export type GridItemProps = {
	image: GatsbyImageType;
	link: string;
	tagline: string;
	title: string;
};

const GridItem: FC<GridItemProps> = ({ image, link, title, tagline }) => (
	<Link className="grid-item" to={link}>
		{image.image && (
			<GatsbyImage
				className="grid-item-image"
				image={image.image}
				alt={image.title}
			/>
		)}
		<p className="grid-item-tagline is-style-p-with-mobile">{tagline}</p>
		<h2 className="grid-item-title is-style-h2-small">{title}</h2>
	</Link>
);

export default GridItem;
