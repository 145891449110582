/**
 * External dependencies
 */
import {
	FC,
	forwardRef,
	HTMLAttributes,
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Canvg, { Document } from 'canvg';

/**
 * Internal dependencies
 */
import { mapRange } from 'utils';
import { useCombinedRefs, useFontEffect } from 'hooks';

export type CanvasProps = {
	/**
	 * Brush size in pixels.
	 */
	brushSize?: number;

	/**
	 * Minimal brush size for small screens..
	 */
	minBrushSize?: number;

	/**
	 * Callback function called when the canvas gets rendered.
	 */
	onReady?: () => any;

	/**
	 * Callback function called when the reveal treshold gets reached.
	 */
	onTresholdReach?: () => any;

	/**
	 * The ratio of the erased area and the entire canvas size when the callback will be called.
	 */
	revealTreshold?: number;

	/**
	 * Source SVG element.
	 */
	svg: SVGSVGElement;
} & HTMLAttributes<HTMLDivElement>;

const Canvas: FC<CanvasProps> = forwardRef<HTMLCanvasElement, CanvasProps>(
	(
		{
			brushSize = 138,
			minBrushSize = 70,
			onReady,
			onTresholdReach,
			revealTreshold = 0.5,
			svg,
			...props
		},
		forwardedRef
	) => {
		const [ready, setReady] = useState<boolean>(false);
		const [reveal, setReveal] = useState<boolean>(false);
		const [ctx, setCtx] = useState<CanvasRenderingContext2D>();

		/**
		 * Full canvas width.
		 */
		const [width, setWidth] = useState<number>();

		/**
		 * Canvg instance.
		 */
		const canvg = useRef<Canvg>();

		const canvasRef = useCombinedRefs(forwardedRef);
		const tmpCanvas = useRef<HTMLCanvasElement>();
		const wrapRef = useRef<HTMLDivElement>(null);

		/**
		 * Real source SVG image dimmentions.
		 */
		const height = useMemo(() => {
			if (!svg) {
				return 0;
			}

			return svg.getBoundingClientRect().height;
		}, [svg]);

		useEffect(() => {
			if (canvasRef.current) {
				const newCtx = canvasRef.current.getContext('2d');

				if (newCtx) {
					setCtx(newCtx);
				}
			}
		}, [canvasRef]);

		const getCanvas = useCallback(
			(
				ref: MutableRefObject<HTMLCanvasElement | undefined>,
				w: number,
				h: number
			) => {
				if (!ref.current) {
					ref.current = Document.createCanvas(w, h);
				}

				return ref.current;
			},
			[]
		);

		const renderBaseSvg = useCallback(
			(
				tmp: HTMLCanvasElement,
				canvgWidth: number,
				canvgHeight: number
			) => {
				if (!canvg.current) {
					const tmpCtx = tmp.getContext('2d');

					canvg.current = Canvg.fromString(tmpCtx, svg.outerHTML, {});

					setReady(true);
				} else if (canvg.current) {
					canvg.current.resize(canvgWidth, canvgHeight);
				}

				canvg.current.render();
			},
			[svg]
		);

		const prepareCanvas = useCallback(
			(canvas: HTMLCanvasElement) => {
				if (!ctx) {
					return;
				}

				ctx.fillStyle = '#110d29';
				ctx.fillRect(0, 0, canvas.width, canvas.height);
			},
			[ctx]
		);

		useFontEffect(
			() => {
				if (!ctx || !canvasRef.current || !width || !height) {
					return;
				}

				const localTmpCanvas = getCanvas(
					tmpCanvas,
					canvasRef.current.width,
					canvasRef.current.height
				);

				if (!localTmpCanvas) {
					return;
				}

				renderBaseSvg(localTmpCanvas, width, height);
				prepareCanvas(canvasRef.current);

				ctx.drawImage(localTmpCanvas, 0, 0);

				onReady && onReady();
			},
			['sofia-pro', 'MontHeavy'],
			[ctx, width, height, renderBaseSvg, prepareCanvas]
		);

		useEffect(() => {
			if (!wrapRef.current) {
				return;
			}

			setWidth(wrapRef.current.clientWidth);
		}, [wrapRef]);

		const brushSizeFactor = useMemo(() => {
			if (!width) {
				return 1;
			}

			const factor = mapRange(width, 375, 1500, minBrushSize, brushSize);

			return factor / brushSize;
		}, [brushSize, minBrushSize, width]);

		const getBrushSize = useCallback(
			(size: number = brushSize) => size * brushSizeFactor,
			[brushSizeFactor, brushSize]
		);

		const coords = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

		const updateCursorPosition = useCallback(
			(x: number, y: number) => {
				if (!canvasRef.current) {
					return;
				}

				coords.current.x =
					x - canvasRef.current.offsetLeft + window.scrollX;
				coords.current.y =
					y - canvasRef.current.offsetTop + window.scrollY;
			},
			[canvasRef]
		);

		const sketch = useCallback(
			(x: number, y: number) => {
				if (!ctx || !canvasRef.current) {
					return;
				}

				if (coords.current.x === 0 && coords.current.y === 0) {
					updateCursorPosition(x, y);
				}

				ctx.globalCompositeOperation = 'source-atop';

				ctx.lineWidth = getBrushSize(brushSize + 20);
				ctx.lineJoin = ctx.lineCap = 'round';
				ctx.strokeStyle = '#302940';

				ctx.beginPath();
				ctx.moveTo(coords.current.x, coords.current.y);
				updateCursorPosition(x, y);
				ctx.lineTo(coords.current.x, coords.current.y);

				ctx.stroke();

				ctx.globalCompositeOperation = 'destination-out';
				ctx.lineWidth = getBrushSize();
				ctx.stroke();

				const data = ctx.getImageData(
					0,
					0,
					canvasRef.current.width,
					canvasRef.current.height
				).data;

				const nrOfPixels = data.length / 4;
				let transparent = 0;

				for (let i = 3; i < data.length; i += 4) {
					transparent += data[i] ? 0 : 1;
				}

				if (transparent / nrOfPixels >= revealTreshold) {
					setReveal(true);
				}
			},
			[
				brushSize,
				ctx,
				canvasRef,
				getBrushSize,
				updateCursorPosition,
				revealTreshold,
			]
		);

		const handleMouseMove = useCallback(
			(event: MouseEvent) => sketch(event.clientX, event.clientY),
			[sketch]
		);

		const handleTouchMove = useCallback(
			(event: TouchEvent) =>
				sketch(event.touches[0].clientX, event.touches[0].clientY),
			[sketch]
		);

		useEffect(() => {
			if (!ready) {
				return;
			}

			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('touchmove', handleTouchMove);

			return () => {
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('touchmove', handleTouchMove);
			};
		}, [ready, handleMouseMove, handleTouchMove]);

		useEffect(() => {
			reveal && onTresholdReach && onTresholdReach();
		}, [onTresholdReach, reveal, sketch]);

		return (
			<div {...props} ref={wrapRef}>
				<canvas
					height={width ? height : 0}
					ref={canvasRef}
					width={width}
				/>
			</div>
		);
	}
);

export default Canvas;
