/**
 * External dependencies
 */
import { CSSProperties } from 'react';
import { Element, Root } from 'hast';
import { Plugin } from 'unified';
import { visit, Test, Visitor } from 'unist-util-visit';

export type RehypeStylePluginOptions = {
	style?: CSSProperties;
};

export const rehypeStylePlugin: Plugin<[RehypeStylePluginOptions], Root> =
	({ style }) =>
	(tree) => {
		if (style) {
			const test: Test = (node, index, parent) => parent?.type === 'root';

			const visitor: Visitor = (node) => {
				if (node.type === 'element') {
					const element = node as Element;
					element.properties = {
						...element.properties,
						style,
					};
				}
			};

			visit(tree, test, visitor);
		}

		return tree;
	};
