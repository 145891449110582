/**
 * Internal dependencies
 */
import type { RootState } from './types';

const initialState: RootState = {
	heroDot: null,
	navBarColor: 'dark',
	navBarOffset: 0,
	scrollbarWidth: 0,
};

export default initialState;
