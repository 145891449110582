/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import './styles.scss';
import { CursorFollowerTarget } from 'components';
import { LinkArray } from 'types';

type MenuProps = {
	items: LinkArray;
	onClick?: () => any;
};

const Menu: FC<MenuProps> = ({ items, onClick }) => (
	<ul className="navbar-menu">
		{items.map((item, index) => (
			<li className="navbar-menu-item" key={index}>
				<CursorFollowerTarget>
					<Link to={item.url} onClick={onClick}>
						{item.label}
					</Link>
				</CursorFollowerTarget>
			</li>
		))}
	</ul>
);

export default Menu;
