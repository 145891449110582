/**
 * External dependencies
 */
import { FC, CSSProperties } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type ExclamationProps = {
	color?: string;
	size?: number;
	expanded?: boolean;
};

const Exclamation: FC<ExclamationProps> = ({
	color,
	size,
	expanded = false,
}) => {
	const styles: CSSProperties = {};

	if (color) {
		styles.color = color;
	}

	if (size) {
		styles.fontSize = `${size}em`;
	}

	return (
		<span
			className={classnames('exclamation', { expanded })}
			style={styles}
		>
			!
		</span>
	);
};

export default Exclamation;
