/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button, Container, FullWidth } from 'components';
import { ButtonProps } from 'components/Button/types';

type HeaderHeroProps = {
	button?: ButtonProps;
	title: string;
	subtitle: string;
};

const HeaderHero: FC<HeaderHeroProps> = ({ title, subtitle, button }) => (
	<FullWidth className="header-hero">
		<Container>
			<div className="inner">
				<div className="title">{title}</div>
				<div className="subtitle">
					{subtitle}
					{button && <Button {...button} />}
				</div>
			</div>
		</Container>
	</FullWidth>
);

export default HeaderHero;
