/**
 * External dependencies
 */
import { FC } from 'react';
import { navigate } from 'gatsby';

/**
 * Internal dependencies
 */
import { Container, Filters, FullWidth, Image, PersonInfo } from 'components';
import { Image as ImageType } from 'types';
import { PersonInfoProps } from 'components/PersonInfo';
import './styles.scss';

type PostHeroProps = {
	author: PersonInfoProps;
	image: ImageType;
	title: string;
	category: string;
	categoryUrl: string;
};

const PostHero: FC<PostHeroProps> = ({
	author,
	image,
	title,
	category,
	categoryUrl,
}) => (
	<FullWidth className="post-hero">
		<Container className="post-hero-inner">
			<div className="post-hero-half">
				<button
					className="post-hero-category-button"
					onClick={() => navigate(categoryUrl)}
				>
					{category}
				</button>
				<Filters
					onChange={(value) => navigate(categoryUrl)}
					items={{ category: category }}
					value={category as keyof typeof category}
				/>
				<h1 className="post-hero-title is-style-h1-small">{title}</h1>
				<PersonInfo prefix="by" {...author} />
			</div>
			<Image
				image={{
					src: image.src,
					title: image.title,
				}}
			/>
		</Container>
	</FullWidth>
);

export default PostHero;
