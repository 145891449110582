/**
 * External dependencies
 */
import { FC, PropsWithChildren, useEffect } from 'react';

/**
 * Internal dependencies
 */
import { setScrollbarWidth, useDispatch, useSelector } from 'store';

const ScrollbarWidthMonitor: FC<PropsWithChildren> = ({ children }) => {
	const dispatch = useDispatch();
	const scrollbarWidth = useSelector((state) => state.scrollbarWidth);

	useEffect(() => {
		const handler = () => {
			const windowWidth = Math.min(
				window.screen.width,
				window.innerWidth
			);

			const newScrollbarWidth = Math.max(
				0,
				windowWidth - document.body.clientWidth
			);

			const hasCurrentValue =
				!!document.documentElement.style.getPropertyValue(
					'--scrollbar-width'
				).length;

			if (!hasCurrentValue || newScrollbarWidth !== scrollbarWidth) {
				document.documentElement.style.setProperty(
					'--scrollbar-width',
					`${newScrollbarWidth}px`
				);

				dispatch(setScrollbarWidth(newScrollbarWidth));
			}
		};

		handler();

		window.addEventListener('load', handler);
		window.addEventListener('resize', handler);

		return () => {
			window.removeEventListener('load', handler);
			window.removeEventListener('resize', handler);
		};
	}, [scrollbarWidth, dispatch]);

	return <>{children}</>;
};

export default ScrollbarWidthMonitor;
