/**
 * External dependencies
 */
import { FC, ButtonHTMLAttributes, forwardRef } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type ToggleProps = {
	isActive?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Toggle: FC<ToggleProps> = forwardRef<HTMLButtonElement, ToggleProps>(
	({ className, isActive, ...props }, ref) => (
		<button
			className={classnames(className, 'navbar-toggle', {
				'is-active': isActive,
			})}
			ref={ref}
			aria-label="Toggle navigation"
			{...props}
		/>
	)
);

export default Toggle;
