import ania from 'images/team/ania.jpg';
import ania_avatar from 'images/team/ania-avatar.webp';
import david_h_avatar from 'images/team/david-h-avatar.png';
import kacper_b_avatar from 'images/team/kacper-b-avatar.jpg';
import julia from 'images/team/julia.jpg';
import julia_avatar from 'images/team/julia-avatar.jpg';
import karolina from 'images/team/karolina.jpg';
import karolina_avatar from 'images/team/karolina-avatar.webp';
import krzysiek from 'images/team/krzysiek.jpg';
import krzysiek_avatar from 'images/team/krzysiek-avatar.webp';
import kuba from 'images/team/kuba.jpg';
import kuba_avatar from 'images/team/kuba-avatar.webp';
import wojtek from 'images/team/wojtek.jpg';
import wojtek_avatar from 'images/team/wojtek-avatar.webp';

const config = [
	{
		id: 'jakub_m',
		active: true,
		image: kuba,
		avatar: kuba_avatar,
		name: 'Jakub Mikita',
		position: 'CEO & Senior developer',
		description: (
			<>
				<p>
					The Master Organizer who, like a chameleon, finds his place
					in roles ranging from CEO to Senior Developer, Project
					Manager, and marketing support, and in private life, as a
					proud father of one.
				</p>
				<p>
					He excels in team management, business process creation,
					client negotiations, and applying agile methods for company
					growth. Once the creator of one of the oldest Polish
					WordPress blogs - WPART, today he can be persuaded to write
					articles that captivate from the first sentence.
				</p>
			</>
		),
	},
	{
		id: 'wojtek_s',
		active: true,
		image: wojtek,
		avatar: wojtek_avatar,
		name: 'Wojtek Szałkiewicz',
		position: 'Tech Lead / Senior Full-stack developer',
		description: (
			<>
				<p>
					A virtuoso who, after stepping away from coding, takes up
					the violoncello and performs across Poland. Wojtek is the
					tech lead of our development team, whose coding beginnings
					date back to HTML 4.0. Years of experience and extraordinary
					persistence, have led Wojtek to a place where there are no
					unsolvable problems for him.
				</p>
				<p>Currently, Wojtek works mostly with React.</p>
				<p>
					His posts are highly technical, aimed at experienced
					frontend developers.
				</p>
			</>
		),
	},
	{
		id: 'anna_s',
		active: true,
		image: ania,
		avatar: ania_avatar,
		name: 'Anna Szałkiewicz',
		position: 'Product Owner / QA Manager',
		description: (
			<>
				<p>
					The Mistress of Detail and the most vigilant eye in the
					entire company. As our Product Owner and QA Specialist, Anna
					ensures projects progress as planned, keeping an eye on
					deadlines and workflow.
				</p>
				<p>
					She turns the ordinary into the extraordinary by combining
					her knowledge with modern project management techniques, and
					in her private life, she sews original creations as a
					Fashion Design student.
				</p>
				<p>
					From her articles, you will learn how to manage and optimize
					team work in digital projects and what aspects to consider
					in web development. Her writing is accompanied by her
					four-legged friend, Figa.
				</p>
			</>
		),
	},
	{
		id: 'krzysztof_g',
		active: true,
		image: krzysiek,
		avatar: krzysiek_avatar,
		name: 'Krzysztof Grabania',
		position: 'Senior Full-stack developer',
		description: (
			<>
				<p>
					With a seaside breeze comes Krzysztof, Senior Backend
					Developer, speaker, and active participant in the WordPress
					community in Poland. Supposedly loves PHP and Laravel as
					much as his car, allowing him to blend passion with
					experience and share technical insights with us.
				</p>
				<p>
					From his posts, you will learn how to create beautiful
					backends and collaborate with frontend teams without
					conflict.
				</p>
			</>
		),
	},
	{
		id: 'julia_n',
		active: true,
		image: julia,
		avatar: julia_avatar,
		name: 'Julia Nikodem',
		position: 'Graphic Designer & Illustrator',
		description: (
			<>
				<p></p>
			</>
		),
	},
	{
		id: 'karolina_c',
		active: true,
		image: karolina,
		avatar: karolina_avatar,
		name: 'Karolina Czapla',
		position: 'Marketing Specialist',
		description: (
			<>
				<p>
					A Marketer who is slowly making herself at home in the world
					of WordPress and technology. She is adept at digital
					strategy, consumer research, and communicating with clients
					about technology brands. Her posts cover all the digital
					challenges facing users, as well as UX writing, benefit and
					value language, and social media communication tips.
				</p>
				<p>
					Privately, a mother of 3 cats, always ready to provide her
					team with team-building and wellbeing experiences. A million
					ideas per minute and the colorful bird of our team.
				</p>
			</>
		),
	},
	{
		id: 'david_h',
		active: false,
		image: null,
		avatar: david_h_avatar,
		name: 'David Huerta Beltran',
		position: 'Front-end developer',
		description:
			'A Mexican living in Poland, former Junior Front-end Developer at Department of Web',
	},
	{
		id: 'kacper_b',
		active: false,
		image: null,
		avatar: kacper_b_avatar,
		name: 'Kacper B',
		position: 'Front-end developer',
		description: 'Former Junior Front-end Developer at Department of Web',
	},
];

export default config;
