/**
 * Internal dependencies
 */
import { setHeroDot } from '../actions';
import { Reducer } from '../types';
import isAction from '../isAction';
import initialState from '../initialState';

type HeroDot = HTMLSpanElement | null;

const heroDot: Reducer<HeroDot> = (state = initialState.heroDot, action) =>
	isAction(action, setHeroDot) ? action.payload : state;

export default heroDot;
