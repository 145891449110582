/**
 * External dependencies
 */
import { createContext, RefObject } from 'react';

export type CursorFollowerTargetContextValue = {
	removeTargetElement: () => void;
	setTargetElement: (element: HTMLElement | RefObject<HTMLElement>) => void;
	targetElement?: HTMLElement;
};

export const defaultValue: CursorFollowerTargetContextValue = {
	removeTargetElement: () => {},
	setTargetElement: () => {},
	targetElement: undefined,
};

export const CursorFollowerTargetContext =
	createContext<CursorFollowerTargetContextValue>(defaultValue);

export default CursorFollowerTargetContext;
