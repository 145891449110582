/**
 * External dependencies
 */
import { FC, useEffect, useRef, useState, TransitionEvent } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/**
 * Internal dependencies
 */
import './styles.scss';
import {
	BackgroundText,
	Button,
	Container,
	CursorFollowerContainer,
	CursorFollowerHighlight,
	CursorFollowerTarget,
} from 'components';
import { ButtonProps } from 'components/Button/types';
import { Image } from 'types/images';
import { LinkArray } from 'types';
import { setNavBarOffset, useDispatch, useSelector } from 'store';
import Menu from './Menu';
import Toggle from './Toggle';

interface NavBarProps {
	backgroundLines: Array<string>;

	/**
	 * List of menu items
	 */
	items: LinkArray;

	/**
	 * Logo that appears when openning menu
	 */
	logo: Image;

	/**
	 * Content for list of buttons
	 */
	menuButtons: Array<ButtonProps>;

	/**
	 * Content for Button NavBar
	 */
	topButton: ButtonProps;
}

const NavBar: FC<NavBarProps> = ({
	backgroundLines,
	items,
	logo,
	menuButtons,
	topButton,
}) => {
	const navBarColor = useSelector((state) => state.navBarColor);

	const [isHiding, setIsHiding] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const innerRef = useRef<HTMLDivElement>(null);
	const overlayRef = useRef<HTMLDivElement>(null);
	const scrollElementRef = useRef<HTMLDivElement>(null);

	const dispatch = useDispatch();

	useEffect(() => {
		const setOffset = () => {
			if (innerRef.current) {
				const paddingTop = parseInt(
					window.getComputedStyle(innerRef.current).paddingTop
				);

				const offset =
					paddingTop +
					(innerRef.current.clientHeight - paddingTop) / 2;

				dispatch(setNavBarOffset(offset));
			}
		};

		setOffset();
		window.addEventListener('resize', setOffset);

		return () => window.removeEventListener('resize', setOffset);
	}, [innerRef, dispatch]);

	const className = classnames('navbar', `color-${navBarColor}`, {
		'is-hiding': isHiding,
		'is-open': isOpen,
	});

	const toggle = () => {
		if (scrollElementRef.current && !isOpen) {
			disableBodyScroll(scrollElementRef.current, {
				reserveScrollBarGap: true,
			});
		}

		if (isOpen) {
			setIsHiding(true);
		}

		setIsOpen(!isOpen);
	};

	const onTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
		if (
			scrollElementRef.current &&
			e.target === overlayRef.current &&
			e.propertyName === 'opacity' &&
			!isOpen
		) {
			enableBodyScroll(scrollElementRef.current);
			setIsHiding(false);
		}
	};

	return (
		<div className={className}>
			<div className="navbar-inner" ref={innerRef}>
				<Container flexbox>
					<CursorFollowerTarget>
						<Toggle onClick={toggle} isActive={isOpen} />
					</CursorFollowerTarget>
					<div className="right-wrapper">
						<Button {...topButton} className="navbar-button" />
						<CursorFollowerTarget>
							<Link
								className="navbar-logo-link"
								onClick={toggle}
								to="/"
							>
								<img
									className="navbar-logo"
									src={logo.src}
									alt={logo.title}
								/>
							</Link>
						</CursorFollowerTarget>
					</div>
				</Container>
			</div>
			<CursorFollowerContainer
				className="navbar-overlay"
				follower={<CursorFollowerHighlight targetScale={1.65} />}
				ref={overlayRef}
				contentRef={scrollElementRef}
				onTransitionEnd={onTransitionEnd}
				refreshToken={Date.now().toString()}
			>
				<BackgroundText>{backgroundLines[0]}</BackgroundText>
				<div className="navbar-overlay-inner">
					<div className="navbar-overlay-decorators" />
					<div className="navbar-overlay-content">
						<Menu items={items} onClick={toggle} />
						<div className="navbar-buttons">
							{menuButtons.map((socialButton, index) => (
								<CursorFollowerTarget key={index}>
									<Button {...socialButton} />
								</CursorFollowerTarget>
							))}
						</div>
					</div>
				</div>
				<BackgroundText position="right">
					{backgroundLines[1]}
				</BackgroundText>
			</CursorFollowerContainer>
		</div>
	);
};

export default NavBar;
