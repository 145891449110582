/**
 * Internal dependencies
 */
import { setScrollbarWidth } from '../actions';
import { Reducer } from '../types';
import isAction from '../isAction';
import initialState from '../initialState';

const scrollbarWidth: Reducer<number> = (
	state = initialState.scrollbarWidth,
	action
) => (isAction(action, setScrollbarWidth) ? action.payload : state);

export default scrollbarWidth;
