/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type Logo = {
	image: string;
	title: string;
};

type LogosProps = {
	limitSize?: boolean;
	logos: Array<Logo>;
	logosAlign?: 'left' | 'right' | 'center';
	title?: string;
	titleAlign?: 'left' | 'right' | 'center';
	titleMargin?: 'small' | 'large';
};

const Logos: FC<LogosProps> = ({
	limitSize = true,
	logos,
	logosAlign = 'left',
	title,
	titleAlign = 'center',
	titleMargin = 'large',
}) => (
	<div className="logos">
		{title && (
			<h3
				className={classnames(
					'title',
					`align-${titleAlign}`,
					`margin-${titleMargin}`
				)}
			>
				{title}
			</h3>
		)}
		<div
			className={classnames('logos-grid', `align-${logosAlign}`, {
				'limit-size': limitSize,
			})}
		>
			{logos.map((logo, key) => (
				<div className="item" key={key}>
					<div className="image-wrap">
						<img src={logo.image} alt={logo.title} />
					</div>
				</div>
			))}
		</div>
	</div>
);

export default Logos;
