/**
 * External dependencies
 */
import { useMemo, useRef, useState } from 'react';
import useAnimationFrame from 'use-animation-frame';

/**
 * Internal dependencies
 */
import { Point } from 'types';
import { useCursorPosition } from 'hooks';
import { getOffset } from 'utils';

export type UseCalculateCursorFollowerParams = {
	speed?: number;
	targetElement?: HTMLElement;
	targetPosition?: Point;
};

export type UseCalculateCursorFollower = {
	(params: UseCalculateCursorFollowerParams): {
		cursor: Point;
		follower: Point;
	};
};

const pointZero = { x: 0, y: 0 };

export const useCalculateCursorFollower: UseCalculateCursorFollower = ({
	speed = 500,
	targetElement,
}) => {
	const [position, setPosition] = useState<Point>(pointZero);
	const cursorPosition = useCursorPosition();
	const hasTarget = useRef<Boolean>(false);
	const targetPositionRef = useRef<Point>(pointZero);

	targetPositionRef.current = (() => {
		if (!targetElement) {
			return cursorPosition.current;
		}

		const { offset, rect } = getOffset(targetElement);

		return {
			x: Math.round(offset.x + rect.width / 2),
			y: Math.round(offset.y + rect.height / 2),
		};
	})();

	hasTarget.current = useMemo(() => !!targetElement, [targetElement]);

	const realSpeed = useMemo(
		() =>
			Math.round(
				Math.tan(Math.min(Math.max(speed, 1), 1000) / 650) * 109.6947
			),
		[speed]
	);

	useAnimationFrame(
		({ delta: deltaTime }) => {
			if (deltaTime <= 0) {
				// This happens when changing `speed`.
				return;
			}

			setPosition((prevPosition) => {
				const factor = Math.min((deltaTime * realSpeed * 6) / 100, 1);

				const getDelta = (pos: keyof typeof position) =>
					targetPositionRef.current
						? Math.round(
								(targetPositionRef.current[pos] -
									prevPosition[pos]) *
									factor
						  )
						: 0;

				return {
					x: prevPosition.x + getDelta('x'),
					y: prevPosition.y + getDelta('y'),
				};
			});
		},
		[realSpeed]
	);

	return {
		cursor: cursorPosition.current,
		follower: position,
	};
};

export default useCalculateCursorFollower;
