/**
 * External dependencies
 */
import {
	createContext,
	FC,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
} from 'react';

export const RemValueContext = createContext<number>(16);

export const RemValueProvider: FC<PropsWithChildren> = ({ children }) => {
	const [remValue, setRemValue] = useState<number>(16);
	const [span, setSpan] = useState<HTMLSpanElement | null>(null);

	const interval = useRef<number>();

	useEffect(() => {
		if (!span) {
			return;
		}

		const detect = () => {
			if (span) {
				const newFontSize = parseInt(
					window.getComputedStyle(span).fontSize,
					10
				);

				if (newFontSize !== remValue) {
					setRemValue(newFontSize);
				}
			}
		};

		detect();

		interval.current = window.setInterval(detect, 200);

		return () => window.clearInterval(interval.current);
	}, [remValue, span]);

	return (
		<RemValueContext.Provider value={remValue}>
			<span
				style={{ fontSize: '1rem', position: 'absolute' }}
				ref={(el) => setSpan(el)}
			/>
			{children}
		</RemValueContext.Provider>
	);
};

export const RemValueConsumer = RemValueContext.Consumer;
