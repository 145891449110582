/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

/**
 * Internal dependencies
 */
import { createStore } from 'store';
import { RootState } from 'store/types';

type StoreProviderProps = PropsWithChildren<{
	initialState?: Partial<RootState>;
}>;

const StoreProvider: FC<StoreProviderProps> = ({ children, initialState }) => (
	<Provider store={createStore(initialState)}>{children}</Provider>
);

export default StoreProvider;
