/**
 * External dependencies
 */
import { FC, ReactNode } from 'react';

/**
 * Internal dependencies
 */
import { Container, FullWidth, Text } from 'components';
import './styles.scss';
import classnames from 'classnames';

type BlogHeroProps = {
	description?: ReactNode;
	subtitle?: string;
	title: string;
	afterSubtitle?: ReactNode;
	isCategory?: boolean;
};

const BlogHero: FC<BlogHeroProps> = ({
	description,
	subtitle,
	title,
	afterSubtitle,
	isCategory = false,
}) => (
	<FullWidth className={classnames('blog-hero', isCategory && 'category')}>
		<Container className="blog-hero-inner">
			<div className="blog-hero-heading-wrap">
				<div className="is-style-h1">
					<p className="blog-hero-heading">{title}</p>
				</div>
				{subtitle && <p className="blog-hero-subtitle">{subtitle}</p>}
				{afterSubtitle}
			</div>
			{description && (
				<Text className="blog-hero-description">{description}</Text>
			)}
		</Container>
	</FullWidth>
);

export default BlogHero;
