/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Image as ImageType } from 'types';
import './styles.scss';

type ImageProps = {
	image: ImageType;
};

const Image: FC<ImageProps> = ({ image }) => (
	<img className="image" src={image.src} alt={image.title} />
);

export default Image;
