const validationErrors = {
	name: {
		required: 'Please provide your name.',
	},
	email: {
		required: 'Please provide an email.',
		pattern: 'Please provide a valid email address.',
	},
	brief: {
		required: 'Enter the project brief',
	},
	policy: {
		required: 'Confirm you agree to the Privacy Policy',
	},
	required: 'This field is required.',
} as const;

export default validationErrors;
