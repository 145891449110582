/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button, Text } from 'components';
import { ButtonProps } from 'components/Button/types';
import { Image } from 'types';
import { Alignment } from 'types/text';

interface MeetTheTeamProps {
	button: ButtonProps;
	images: [Image, Image];
	text: string;
	textAlign?: Alignment;
}

const MeetTheTeam: FC<MeetTheTeamProps> = ({
	button,
	images,
	text,
	textAlign = 'left',
}) => (
	<div className="meet-the-team">
		<div className="column">
			<Text className={`align-${textAlign}`}>{text}</Text>
			<img src={images[0].src} alt={images[0].title} />
		</div>
		<div className="column">
			<img src={images[1].src} alt={images[1].title} />
			<div className="btn-container">
				<Button {...button} />
			</div>
		</div>
	</div>
);

export default MeetTheTeam;
