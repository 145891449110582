/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import { RemValueContext } from 'contexts';
import { pxToRem as basePxToRem, remToPx as baseRemToPx } from 'utils';

const useRemUtils = () => {
	const remValue = useContext(RemValueContext);

	const pxToRem = (px: string | number) => basePxToRem(px, remValue);
	const remToPx = (rem: string | number) => baseRemToPx(rem, remValue);

	return {
		pxToRem,
		remToPx,
		remValue,
	};
};

export default useRemUtils;
