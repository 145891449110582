/**
 * External dependencies
 */
import { FC, ReactElement } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';

type ShareButtonProps = {
	href: string;
	icon: ReactElement;
};

const ShareButton: FC<ShareButtonProps> = ({ href, icon }) => (
	<a
		className="share-button"
		href={href}
		target="_blank"
		rel="nofollow noreferrer"
	>
		{icon}
	</a>
);

export default ShareButton;
