/**
 * External dependencies
 */
import { Element, Root } from 'hast';
import { Plugin } from 'unified';
import { visit, Test, Visitor } from 'unist-util-visit';

export type RehypeClassNamePluginOptions = {
	className?: string;
};

export const rehypeClassNamePlugin: Plugin<
	[RehypeClassNamePluginOptions],
	Root
> =
	({ className }) =>
	(tree) => {
		const test: Test = (node, index, parent) => parent?.type === 'root';

		const visitor: Visitor = (node) => {
			if (node.type === 'element') {
				const element = node as Element;
				element.properties = {
					...element.properties,
					className,
				};
			}
		};

		visit(tree, test, visitor);

		return tree;
	};
