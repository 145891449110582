/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import { MenuProps } from '../types';

const Contact: FC<MenuProps> = ({ links }) => {
	return (
		<ul>
			{links.map((link, index) => (
				<li key={index}>
					<Link to={link.link}>{link.title}</Link>
				</li>
			))}
		</ul>
	);
};

export default Contact;
