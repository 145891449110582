/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Position } from 'types';

type CursorFollowerProps = PropsWithChildren<Position>;

/**
 * Cursor follower decription!
 */
const CursorFollower: FC<CursorFollowerProps> = ({ children, left, top }) => {
	const style = {
		top: `${top}px`,
		left: `${left}px`,
	};

	return (
		<div className="cursor-follower" style={style}>
			{children}
		</div>
	);
};

export default CursorFollower;
