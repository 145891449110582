/**
 * External dependencies
 */
import { FC, useState } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { ReactComponent as Copy } from 'images/copy.svg';
import { SocialMediaShare } from '../ShareButtons/types';
import './styles.scss';

const CopyButton: FC<SocialMediaShare> = ({ href }) => {
	const [isCopied, setIsCopied] = useState(false);

	const handleCopy = () => {
		navigator.clipboard.writeText(href);

		setIsCopied(true);

		setTimeout(() => {
			setIsCopied(false);
		}, 500);
	};

	return (
		<button
			className={classnames('copy-button', {
				'is-copied': isCopied,
			})}
			onClick={handleCopy}
		>
			<Copy className="copy-button-icon" />
		</button>
	);
};

export default CopyButton;
