/**
 * External dependencies
 */
import { FC, forwardRef, HTMLAttributes, Ref } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type ContainerProps = {
	flexbox?: boolean;
	full?: boolean;
	ref?: Ref<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

const Container: FC<ContainerProps> = forwardRef<
	HTMLDivElement,
	ContainerProps
>(({ children, className, flexbox = false, full, ...props }, ref) => (
	<div
		className={classnames(className, 'container', {
			full,
			'is-flex': flexbox,
		})}
		ref={ref}
		{...props}
	>
		{children}
	</div>
));

export default Container;
