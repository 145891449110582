/**
 * External dependencies
 */
import { FC, InputHTMLAttributes } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type InputProps = {
	name: string;
	validation?: RegisterOptions;
	className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input: FC<InputProps> = ({ name, validation, className, ...props }) => {
	const formContext = useFormContext();
	const hasError = formContext.control.getFieldState(name).error;

	return (
		<div
			className={classnames('input', {
				'has-error': hasError,
				'is-valid': !hasError && formContext.formState.isValid,
			})}
		>
			<input
				className={classnames('input-field', className)}
				{...props}
				{...(formContext &&
					validation &&
					formContext.register(name, {
						...validation,
					}))}
			/>
		</div>
	);
};

export default Input;
