/**
 * External dependencies
 */
import { debounce } from 'lodash';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Internal dependencies
 */
import { isBrowser } from 'utils';

type UseResizableEffectOptions = {
	watchWidthOnly?: boolean;
};

const useResizableEffect = (
	callback: EffectCallback,
	deps?: DependencyList,
	options?: UseResizableEffectOptions
) => {
	const { watchWidthOnly } = Object.assign(
		{
			watchWidthOnly: false,
		},
		options
	);

	const windowWidth = useRef<number>(isBrowser() ? window.innerWidth : 0);

	useEffect(() => {
		callback();

		const debouncedCallback = debounce(() => {
			if (!watchWidthOnly || window.innerWidth !== windowWidth.current) {
				callback();
			}

			windowWidth.current = window.innerWidth;
		}, 250);

		window.addEventListener('resize', debouncedCallback);

		return () => window.removeEventListener('resize', debouncedCallback);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, deps);
};

export default useResizableEffect;
