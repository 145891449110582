/**
 * External dependencies
 */
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Exclamation } from 'components';
import { getOffset } from 'utils';
import { Point } from 'types';
import { useSelector } from 'store';
import { useFontEffect, useIsMounted, useTransitionEnd } from 'hooks';

type LoaderProps = {};

const Loader: FC<LoaderProps> = () => {
	const [dotPosition, setDotPosition] = useState<Point>();
	const [isExclamationExpanded, setIsExclamationExpanded] =
		useState<boolean>(true);
	const [init, setInit] = useState<boolean>(false);
	const [isFinished, setIsFinished] = useState<boolean>(false);

	const scrollbarWidth = useSelector((state) => state.scrollbarWidth);
	const heroDot = useSelector((state) => state.heroDot);

	/**
	 * This ref is needed for the `body-scroll-lock` functions. It doesn't do anything.
	 */
	const ref = useRef<HTMLDivElement>(null);

	const getFadeInProps = useTransitionEnd<HTMLDivElement>({
		callback: () =>
			setTimeout(() => {
				setIsExclamationExpanded(false);

				if (ref.current) {
					enableBodyScroll(ref.current);
				}
			}, 500),
		classNameBase: 'loader-content',
	});

	const getFadeOutProps = useTransitionEnd({
		callback: () => {
			setIsFinished(true);
		},
		classNameBase: 'loader',
		ref,
	});

	const isMounted = useIsMounted();

	useEffect(() => {
		if (ref.current) {
			const element = ref.current;

			disableBodyScroll(element, {
				reserveScrollBarGap: !!scrollbarWidth,
			});
		}
	}, [ref, scrollbarWidth]);

	useFontEffect(
		() => {
			if (isMounted) {
				if (heroDot) {
					const { offset } = getOffset(heroDot);

					setDotPosition(offset);
				}

				setInit(true);
			}
		},
		['MontHeavy'],
		[heroDot, isMounted]
	);

	if (isFinished) {
		return null;
	}

	const {
		className: loaderContentClassName,
		reset: fadeInReset,
		...loaderContentProps
	} = getFadeInProps(init);

	const { reset, ...fadeOutProps } = getFadeOutProps(!isExclamationExpanded);

	const positionRatio =
		dotPosition && ref.current && dotPosition.x / ref.current?.clientWidth;

	const exclamationStyle =
		positionRatio && positionRatio > 0.4 && positionRatio < 0.6
			? {
					left: `${dotPosition.x}px`,
			  }
			: {
					left: '50%',
					transform: 'translateX(-50%)',
			  };

	return (
		<div {...fadeOutProps}>
			<div
				className={classnames(loaderContentClassName, 'is-style-h1')}
				style={exclamationStyle}
				{...loaderContentProps}
			>
				<p className="exclamation-wrap">
					<Exclamation expanded={isExclamationExpanded} />
				</p>
			</div>
		</div>
	);
};

export default Loader;
