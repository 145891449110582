/**
 * Internal dependencies
 */
import { Point } from 'types';

type GetOffsetReturnType = {
	offset: Point;
	rect: DOMRect;
};

export const getOffset = (element: HTMLElement): GetOffsetReturnType => {
	const rect = element.getBoundingClientRect();

	return {
		offset: {
			x: rect.left + window.scrollX,
			y: rect.top + window.scrollY,
		},
		rect,
	};
};
