/**
 * External dependencies
 */
import { debounce } from 'lodash';
import { useEffect, DependencyList } from 'react';

const useResizeCallback = (callback: () => any, deps?: DependencyList) => {
	useEffect(() => {
		setTimeout(callback, 0);

		const debouncedCallback = debounce(callback, 250);

		window.addEventListener('resize', debouncedCallback);

		return () => window.removeEventListener('resize', debouncedCallback);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, deps);
};

export default useResizeCallback;
