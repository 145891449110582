/**
 * External dependencies
 */
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useFormspark } from '@formspark/use-formspark';

/**
 * Internal dependencies
 */
import { Box, Button, FormField, Spacer } from 'components';
import { getEmailValidationRules } from 'utils';
import './styles.scss';

type NewsletterFormData = {
	email: string;
};

type NewsletterBoxProps = {
	formsparkId: string;
	subtitle: string;
	title: string;
};

const NewsletterBox: FC<NewsletterBoxProps> = ({
	formsparkId,
	subtitle,
	title,
}) => {
	const formProps = useForm<NewsletterFormData>();

	const [submit, submitting] = useFormspark({
		formId: formsparkId,
	});

	const onSubmit: SubmitHandler<NewsletterFormData> = (data) => {
		submit({
			email: data.email,
		});
	};

	return (
		<Box>
			<div className="newsletter-box">
				<h2 className="title is-style-box-title">{title}</h2>
				<p className="text is-style-box-p">{subtitle}</p>
				<FormProvider {...formProps}>
					<form
						className="newsletter-box-form"
						onSubmit={formProps.handleSubmit(onSubmit)}
						id={formsparkId}
					>
						<FormField<keyof NewsletterFormData>
							validation={getEmailValidationRules()}
							name="email"
							placeholder="Enter your email"
							className="newsletter-box-input"
						/>
						<Spacer height={38} />
						<Button disabled={submitting}>Subscribe</Button>
					</form>
				</FormProvider>
			</div>
		</Box>
	);
};

export default NewsletterBox;
