/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import { FirstVisitCheckContext } from 'contexts';

const useFirstVisitCheck = () => {
	const visitedBefore = useContext(FirstVisitCheckContext);

	return {
		isFirstVisit: !visitedBefore,
	};
};

export default useFirstVisitCheck;
