/**
 * External dependencies
 */
import { FC } from 'react';
import seedrandom from 'seedrandom';

/**
 * Internal dependencies
 */
import { ContactProps } from '../types';

const Contact: FC<ContactProps> = ({ email, shiftPeople }) => {
	const date = new Date();
	const rng = seedrandom(
		`${date.getFullYear()}${date.getMonth()}${date.getDate()}`
	);
	const key = Math.floor(rng() * (shiftPeople.length - 1));

	return (
		<>
			<a href={`mailto:${email}`}>{email}</a>
			<p>Today's shift:</p>
			<p>{shiftPeople[key]}</p>
		</>
	);
};

export default Contact;
