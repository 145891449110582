/**
 * External dependencies
 */
import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';

/**
 * Internal dependencies
 */
import { Root } from 'components';

type Args = WrapRootElementNodeArgs | WrapRootElementBrowserArgs;

export const wrapRootElement = ({ element }: Args) => <Root>{element}</Root>;
