/**
 * Internal dependencies
 */
import { ActionCreator, ActionType, PlainActionCreator } from './types';

export const createPlainAction = (type: ActionType): PlainActionCreator =>
	Object.assign(() => ({ type }), { type });

export const createAction = <P = any>(type: ActionType): ActionCreator<P> =>
	Object.assign((payload: P) => ({ type, payload }), { type });
