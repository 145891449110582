/**
 * External dependencies
 */
import { CSSProperties, FC, forwardRef, HTMLAttributes, memo } from 'react';

/**
 * Internal dependencies
 */
import {
	useCursorFollowerHighlightProps,
	useCursorFollowerTarget,
	useRemUtils,
} from 'hooks';
import { pxToRem } from 'utils';

export type HighlightProps = {
	/**
	 * Highlight radius in pixels.
	 */
	radius?: number;

	/**
	 * If `true`, the highlight's size will change to match the target size.
	 */
	matchTargetSize?: boolean;

	/**
	 * Highlight radius in pixels.
	 */
	targetScale?: number;
};

type HighlightRawProps = HTMLAttributes<HTMLDivElement>;

export const HighlightRaw: FC<HighlightRawProps> = forwardRef<
	HTMLDivElement,
	HighlightRawProps
>(({ style }, ref) => (
	<div className="highlight" style={style} ref={ref}>
		<div className="highlight-inner" />
	</div>
));

const Highlight: FC<HighlightProps> = ({
	matchTargetSize,
	radius,
	targetScale,
}) => {
	const { remToPx } = useRemUtils();
	const { targetElement } = useCursorFollowerTarget();
	const highlightProps = useCursorFollowerHighlightProps();

	matchTargetSize =
		matchTargetSize !== undefined
			? matchTargetSize
			: highlightProps?.matchTargetSize !== undefined
			? highlightProps.matchTargetSize
			: true;
	radius = radius || highlightProps?.radius || 145;
	targetScale = targetScale || highlightProps?.targetScale || 1;

	const style: CSSProperties = {};

	/**
	 * Diameter in rem units created using the base `pxToRem` function with `16` as a `rem` value.
	 */
	const diameterInRem = pxToRem(radius * 2);

	if (matchTargetSize && targetElement) {
		/**
		 * Real diameter in pixels created with the context-aware function using browser's font size to get the actual value in
		 * pixels. This way it can be compared with the target element size to determine wether to use the element's size or
		 * the base highlight size.
		 */
		const diameterInPx = remToPx(diameterInRem);
		const targetWidth =
			targetElement.getBoundingClientRect().width * targetScale;

		style.width =
			diameterInPx > targetWidth
				? `${diameterInRem}rem`
				: `${targetWidth}px`;
	} else {
		style.width = `${diameterInRem}rem`;
	}

	return <HighlightRaw style={style} />;
};

export default memo(Highlight);
