/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { isPlainList, isButtonsList } from './type-guards';
import { BackgroundText, List, Text } from 'components';
import { WhatWeDoProps } from './types';
import ButtonsList from './ButtonsList';

const WhatWeDo: FC<WhatWeDoProps> = ({
	backgroundText,
	items,
	text,
	title,
}) => {
	const _isButtonsList = isButtonsList(items);
	const _isPlainList = isPlainList(items);

	return (
		<div
			className={classnames('what-we-do', {
				'has-buttons-list': _isButtonsList,
				'has-plain-list': _isPlainList,
			})}
		>
			{backgroundText && (
				<BackgroundText textAlign="right" position="right">
					{backgroundText}
				</BackgroundText>
			)}
			<div className="columns">
				<div className="column-left">
					<Text className="title" tag="h2" style="h1">
						{title}
					</Text>
					{_isButtonsList && (
						<Text className="description desktop-hidden">
							{text}
						</Text>
					)}
				</div>
				<div className="column-right">
					{_isPlainList && <List>{items}</List>}
					{_isButtonsList && <ButtonsList items={items} />}
				</div>
			</div>
			<div
				className={classnames('columns', {
					'mobile-hidden': _isButtonsList,
				})}
			>
				<div className="column-left">
					<Text className="description">{text}</Text>
				</div>
			</div>
		</div>
	);
};

export default WhatWeDo;
