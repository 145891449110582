/**
 * External dependencies
 */
import { useRef, useEffect } from 'react';

/**
 * Internal dependencies
 */
import { Point } from 'types';
import { isBrowser } from 'utils';

const useCursorPosition = () => {
	const position = useRef<Point>({ x: 0, y: 0 });
	const scrollPosition = useRef<Point>(
		isBrowser()
			? {
					x: document.documentElement.scrollLeft,
					y: document.documentElement.scrollTop,
			  }
			: position.current
	);

	useEffect(() => {
		const onMouseMove = (e: MouseEvent) => {
			position.current = {
				x: e.pageX,
				y: e.pageY,
			};
		};

		const onScroll = () => {
			const deltaX =
				scrollPosition.current.x - document.documentElement.scrollLeft;

			const deltaY =
				scrollPosition.current.y - document.documentElement.scrollTop;

			scrollPosition.current = {
				x: document.documentElement.scrollLeft,
				y: document.documentElement.scrollTop,
			};

			position.current = {
				x: position.current.x - deltaX,
				y: position.current.y - deltaY,
			};
		};

		const onMouseOver = (e: MouseEvent) => {
			onMouseMove(e);
			document.documentElement.removeEventListener(
				'mouseover',
				onMouseOver
			);
		};

		document.documentElement.addEventListener('mouseover', onMouseOver);
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('mousemove', onMouseMove);
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return position;
};

export default useCursorPosition;
