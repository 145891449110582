/**
 * External dependencies
 */
import { findIndex } from 'lodash';

/**
 * Checks if the given value is an array.
 *
 * @param  value Value to check.
 * @return       `True` if the value is an array, `False` otherwise.
 */
export const isArray = <T = any>(value: any): value is Array<T> =>
	Array.isArray(value);

/**
 * Checks if the given value is an array f certain type.
 *
 * @param  value Value to check.
 * @param  type  Array item type to check.
 * @return       `True` if the value is an array of given type, `False` otherwise.
 */
export const isArrayOfType = <T>(value: any, type: string): value is Array<T> =>
	isArray(value) && findIndex(value, (i) => typeof i !== type) === -1;

/**
 * Checks if the given value is an array of strings.
 *
 * @param  value Value to check.
 * @return       `True` if the value is an array of strings, `False` otherwise.
 */
export const isArrayOfStrings = (value: any): value is Array<string> =>
	isArrayOfType<string>(value, 'string');
