import { BackgroundText, ContentWithLink, HomePageHero, Logos, MeetTheTeam, NavBarColorSwitcher, Spacer, Words } from "components";
import discussing from "images/team-images/diverse-group-young-designers-discussing.jpg";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import interior from "images/team-images/interior-designer-draws-plan-art.jpg";
import sleeve from "images/sleeve.jpg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wupwt from "images/client-logos/wupwt.png";
import * as React from 'react';
export default {
  BackgroundText,
  ContentWithLink,
  HomePageHero,
  Logos,
  MeetTheTeam,
  NavBarColorSwitcher,
  Spacer,
  Words,
  discussing,
  fusebox,
  gpd,
  insanelab,
  interior,
  sleeve,
  thecamels,
  tnbt,
  wpserved,
  wupwt,
  React
};