/**
 * External dependencies
 */
import { FC, ReactElement } from 'react';

/**
 * Internal dependencies
 */
import { GridItemProps } from 'components/GridItem';
import './styles.scss';

type GridProps = {
	children: ReactElement<GridItemProps> | Array<ReactElement<GridItemProps>>;
};

const Grid: FC<GridProps> = ({ children }) => (
	<div className="grid">{children}</div>
);

export default Grid;
