/**
 * External dependencies
 */
import { FC, ReactNode } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type ListProps = {
	children: Array<string | ReactNode>;
	className?: string;
	color?: 'white' | 'dark';
	decoratorColor?: 'blue' | 'dark' | 'lighter' | 'white';
	size?: 'regular' | 'large';
};

const List: FC<ListProps> = ({
	children,
	className,
	color,
	decoratorColor,
	size = 'regular',
}) => (
	<ul
		className={classnames(
			'list',
			`has-${
				color === 'white' && !decoratorColor
					? 'white'
					: decoratorColor ||
					  (size === 'regular' ? 'dark' : 'lighter')
			}-decorator`,
			`has-${color}-color`,
			`is-${size}`,
			className
		)}
	>
		{children.map((item, key) => (
			<li className="list-item" key={key}>
				{item}
			</li>
		))}
	</ul>
);

export default List;
