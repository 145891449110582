/**
 * External dependencies
 */
import { findIndex } from 'lodash';

/**
 * Internal dependencies
 */
import { isArray } from '.';
import { Link } from 'types';

/**
 * Determines if a given value is a `LinkData` object.
 *
 * @param data The data to check.
 * @return     `true` if the data is a `LinkData`, `false` otherwise.
 */
export const isLinkData = <T extends Link>(data: any): data is T =>
	typeof data === 'object' &&
	typeof data.label === 'string' &&
	typeof data.url === 'string';

/**
 * Determines if a given value is an array of `LinkData` objects.
 *
 * @param data The data to check.
 * @return     `true` if the data is an array of `LinkData` objects, `false` otherwise.
 */
export const isLinkDataArray = <T extends Link>(data: any): data is Array<T> =>
	isArray(data) && findIndex(data, (i) => !isLinkData(i)) === -1;
