/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as Linkedin } from 'images/linkedin.svg';
import { SocialMediaShare } from '../types';
import ShareButton from 'components/ShareButtons/ShareButton';

const LinkedinShareButton: FC<SocialMediaShare> = ({ href }) => (
	<ShareButton
		href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}`}
		icon={<Linkedin />}
	/>
);

export default LinkedinShareButton;
