/**
 * External dependencies
 */
import { useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import { isBrowser } from 'utils';

const useMatchMedia = (query: string) => {
	const mediaMatch = isBrowser() ? window.matchMedia(query) : false;
	const [matches, setMatches] = useState<boolean>(
		mediaMatch && mediaMatch.matches
	);

	useEffect(() => {
		if (!mediaMatch) {
			return;
		}

		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);

		mediaMatch.addEventListener('change', handler);

		return () => mediaMatch.removeEventListener('change', handler);
	});

	return matches;
};

export default useMatchMedia;
