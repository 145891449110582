/**
 * External dependencies
 */
import { FC, useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

/**
 * Internal dependencies
 */
import { ReactComponent as Arrow } from 'images/arrow.svg';
import Button from 'components/Button';
import './styles.scss';

type PaginationProps = {
	current: number;
	onChange: (page: number) => void;
	total: number;
};

const Pagination: FC<PaginationProps> = ({ current, onChange, total }) => {
	const [itemsPerPage, setItemsPerPage] = useState(2);

	useEffect(() => {
		const handleItemsPerPage = () => {
			const windowWidth = window.innerWidth;

			setItemsPerPage(windowWidth > 1200 ? 5 : windowWidth > 800 ? 2 : 1);
		};

		handleItemsPerPage();

		window.addEventListener('resize', handleItemsPerPage);

		return () => window.removeEventListener('resize', handleItemsPerPage);
	}, []);

	return (
		<ReactPaginate
			previousLabel={
				<Button>
					<Arrow className="pagination-icon" />
					<span className="pagination-button-text">Previous</span>
				</Button>
			}
			nextLabel={
				<Button>
					<span className="pagination-button-text">Next</span>
					<Arrow className="next pagination-icon" />
				</Button>
			}
			activeClassName="is-current"
			breakLabel="..."
			containerClassName="pagination"
			pageLinkClassName="pagination-number"
			initialPage={current}
			marginPagesDisplayed={1}
			nextClassName="pagination-button-wrap"
			previousClassName="pagination-button-wrap"
			onPageChange={({ selected }) => onChange(selected + 1)}
			pageCount={total}
			pageRangeDisplayed={itemsPerPage}
		/>
	);
};

export default Pagination;
