/**
 * Internal dependencies
 */
import { setNavBarColor } from '../actions';
import { Reducer } from '../types';
import isAction from '../isAction';
import initialState from '../initialState';

type NavBarColor = 'light' | 'dark';

const navBarColor: Reducer<NavBarColor> = (
	state = initialState.navBarColor,
	action
) => (isAction(action, setNavBarColor) ? action.payload : state);

export default navBarColor;
