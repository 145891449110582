/**
 * External dependencies
 */
import { Controller, Scene } from 'react-scrollmagic';
import {
	createRef,
	CSSProperties,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Timeline, Tween } from 'react-gsap';
import { TweenProps } from 'react-gsap/dist/Tween';
import { useStore } from 'react-redux';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Container, FullWidth, Text } from 'components';
import { setHeroDot } from 'store';
import { useResizableEffect } from 'hooks';

type LinesData = Array<{
	height: number;
	startTop: number;
	targetLeft: number;
	targetTop: number;
	width: number;
}>;

type HomePageHeroProps = {
	/**
	 * Font size in `em` relative to base H1 size. `1` equals `250px`.
	 * This value will be used for the start font size before the animation.
	 */
	baseFontSize?: number;

	/**
	 * Main line displayed with large font.
	 */
	headline: string;

	/**
	 * Lines of text which will be animated on scroll.
	 */
	lines: Array<string>;

	/**
	 * Smaller text displayed on the right.
	 */
	message: string;

	/**
	 * Same as `targetFontSize` but used for small screens.
	 */
	mobileTargetFontSize?: number;

	/**
	 * Target font size in `em` relative to base H1 size. `1` equals `250px`.
	 * This value will be used as a target font size for scroll animation.
	 */
	targetFontSize?: number;
};

const HomePageHero: FC<HomePageHeroProps> = ({
	baseFontSize = 1,
	headline,
	lines,
	message,
	mobileTargetFontSize = 0.3273,
	targetFontSize = 0.184,
}) => {
	const { dispatch } = useStore();

	const [linesData, setLinesData] = useState<LinesData>();

	const animationDuration = useRef<number>(0);
	const containerRef = useRef<HTMLDivElement>(null);
	const isMobile = useRef<boolean>(false);
	const sceneRef = useRef<HTMLDivElement>(null);
	const timelineKeyBase = useRef<number>(Date.now());
	const calculatedTargetFontSize = useRef<number>(targetFontSize);

	const refs = useMemo(
		() => Array.from(lines, () => createRef<HTMLParagraphElement>()),
		[lines]
	);

	const setup = useCallback(() => {
		setTimeout(() => {
			window.requestAnimationFrame(() => {
				if (!containerRef.current) {
					return;
				}

				const data: LinesData = [];

				let targetLineHeight = 0;
				let startTop = 0;
				let targetTop = 0;
				let targetLeft = 0;

				for (const { current } of refs) {
					if (!current) {
						continue;
					}

					if (sceneRef.current) {
						isMobile.current =
							window.getComputedStyle(sceneRef.current)
								.display === 'block';
					}

					timelineKeyBase.current = Date.now();
					calculatedTargetFontSize.current = isMobile.current
						? mobileTargetFontSize
						: targetFontSize;

					current.style.fontSize = `${calculatedTargetFontSize.current}em`;
					const targetWidth = current.offsetWidth;
					current.style.fontSize = '';

					const targetHeight =
						calculatedTargetFontSize.current * current.offsetHeight;

					if (
						targetLeft > 0 &&
						targetLeft + targetWidth >
							containerRef.current.clientWidth
					) {
						targetLeft = 0;
						targetTop +=
							calculatedTargetFontSize.current *
							current.offsetHeight;
					}

					data.push({
						height: current.offsetHeight,
						startTop,
						targetLeft,
						targetTop,
						width: current.offsetWidth,
					});

					startTop += current.offsetHeight;
					targetLeft += targetWidth;

					if (targetLeft === 0) {
						targetLineHeight = targetTop + targetHeight;
					} else if (targetHeight > targetLineHeight) {
						targetLineHeight = targetHeight;
					}
				}

				animationDuration.current = startTop;

				setLinesData(data);
			});
		}, 0);
		/**
		 * `baseFontSize` is not directly used in this callback, but it affects how the text sizes are calculated. It needs to
		 * trigger recalculation when changed.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		animationDuration,
		baseFontSize,
		containerRef,
		mobileTargetFontSize,
		refs,
		sceneRef,
		targetFontSize,
	]);

	useResizableEffect(() => setup(), [setup], {
		watchWidthOnly: true,
	});

	const getTweensProps = useCallback(
		(index: number): Array<TweenProps> => {
			if (!linesData) {
				return [];
			}

			const from = {
				fontSize: '1em',
				top: `${linesData[index].startTop}px`,
			};

			const to: CSSProperties = {
				fontSize: `${calculatedTargetFontSize.current}em`,
				left: `${linesData[index].targetLeft}px`,
				top: `${linesData[index].targetTop}px`,
			};

			let midTop = linesData[index].startTop;

			return Array.from(linesData, (v, i) => {
				midTop -=
					v.height *
					(v.targetLeft === 0
						? 1 - calculatedTargetFontSize.current
						: 1);

				const midTo = {
					top: `${midTop}px`,
				};

				const tween: TweenProps = {
					to: i >= index ? to : midTo,
				};

				if (i === 0) {
					tween.from = from;
				}

				return tween;
			});
		},
		[linesData, calculatedTargetFontSize]
	);

	useEffect(() => {
		if (linesData) {
			ScrollTrigger.refresh();
		}
	}, [linesData]);

	const tweensProps = useMemo(
		() => lines.map((line, index) => getTweensProps(index)),
		[getTweensProps, lines]
	);

	const stepDuration = animationDuration.current / lines.length;

	const tweens = (progress: number) =>
		lines.map((line, index) => (
			<Timeline
				key={index}
				target={
					<p className="line" ref={refs[index]}>
						{`${line}\u00A0`}
					</p>
				}
				totalProgress={progress}
				paused
			>
				{tweensProps[index].map((props, key) => (
					<Tween {...props} key={key} />
				))}
			</Timeline>
		));

	const linesMargin = `${isMobile ? -0.1 : -0.144}em`;

	const scene = (progress: number) => (
		<div>
			<div className="scene" ref={sceneRef}>
				<div className="animation is-style-h1" ref={containerRef}>
					<p className="headline">
						{headline}
						<span
							className="dot"
							ref={(dot) => dispatch(setHeroDot(dot))}
						>
							.
						</span>
					</p>
					<Timeline
						totalProgress={progress}
						paused
						target={<div className="lines">{tweens(progress)}</div>}
					>
						<Tween
							from={{ marginTop: '0' }}
							to={{ marginTop: linesMargin }}
							duration={stepDuration}
						/>
						<Tween
							to={{ marginTop: linesMargin }}
							duration={animationDuration.current - stepDuration}
						/>
					</Timeline>
				</div>
				{!isMobile.current && (
					<div className="message">
						<Text style="featured">{message}</Text>
					</div>
				)}
			</div>
		</div>
	);

	const styles: CSSProperties = {
		'--base-font-size': `${baseFontSize}em`,
	};

	return (
		<FullWidth className="home-page-hero" style={styles}>
			<div className="home-page-hero--inner">
				<Container>
					<Controller>
						<div
							className="scene-wrapper"
							key={timelineKeyBase.current}
						>
							<Scene
								duration={animationDuration.current}
								pin
								triggerHook="onLeave"
							>
								{scene}
							</Scene>
							{isMobile.current && (
								<div className="message">
									<Text style="featured">{message}</Text>
								</div>
							)}
						</div>
					</Controller>
				</Container>
			</div>
		</FullWidth>
	);
};

export default HomePageHero;
