/**
 * External dependencies
 */
import { createContext, FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { isBrowser } from 'utils';

export const FirstVisitCheckContext = createContext<boolean>(false);

export const FirstVisitCheckProvider: FC<PropsWithChildren> = ({
	children,
}) => {
	const visitedBefore = isBrowser()
		? window.localStorage.getItem('visitedBefore') === '1'
		: false;

	if (isBrowser() && !visitedBefore) {
		window.localStorage.setItem('visitedBefore', '1');
	}

	return (
		<FirstVisitCheckContext.Provider value={visitedBefore}>
			{children}
		</FirstVisitCheckContext.Provider>
	);
};

export const FirstVisitCheckConsumer = FirstVisitCheckContext.Consumer;
