/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Word } from 'components';

interface WordsProps {
	exclamationColor: string;
	exclamationSize: number;
	lines: Array<string>;
}

const Words: FC<WordsProps> = ({
	exclamationColor,
	exclamationSize,
	lines,
}) => {
	return (
		<div className="words">
			{lines.map((line, key) => (
				<Word {...{ exclamationColor, exclamationSize, key }}>
					{line}
				</Word>
			))}
		</div>
	);
};

export default Words;
