/**
 * External dependencies
 */
import {
	RefObject,
	TransitionEvent,
	useCallback,
	useRef,
	useState,
} from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */

export type TransitionEndProps<T extends HTMLElement = HTMLElement> = {
	className: string;
	onTransitionEnd: (e: TransitionEvent<T>) => void;
	ref: RefObject<T>;
	reset: () => void;
};

export type TransitionEndPropsFunction<T extends HTMLElement = HTMLElement> = (
	start: boolean
) => TransitionEndProps<T>;

export type UseTransitionEndOptions<T extends HTMLElement = HTMLElement> = {
	callback?: () => void;
	classNameBase: string;
	ref?: RefObject<T>;
};

const useTransitionEnd = <T extends HTMLElement = HTMLElement>({
	callback,
	classNameBase,
	ref: customRef,
}: UseTransitionEndOptions<T>): TransitionEndPropsFunction<T> => {
	const ref = useRef<T>(null);
	const [finished, setFinished] = useState<boolean>(false);

	const actualRef = customRef || ref;

	const onTransitionEnd = useCallback(
		(e: TransitionEvent<HTMLElement>) => {
			if (
				!actualRef.current ||
				!(e.target instanceof Node) ||
				!actualRef.current.isSameNode(e.target as Node)
			) {
				return;
			}

			setFinished(true);
			callback && callback();
		},
		[actualRef, callback]
	);

	return useCallback(
		(start: boolean) => ({
			className: classnames(classNameBase, {
				[`${classNameBase}-active`]: start,
				[`${classNameBase}-finished`]: finished,
			}),
			onTransitionEnd,
			ref: actualRef,
			reset: () => setFinished(false),
		}),
		[actualRef, classNameBase, finished, onTransitionEnd]
	);
};

export default useTransitionEnd;
