/**
 * External dependencies
 */
import { createRef, FC, Fragment, useMemo, useState } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button, Text } from 'components';
import { Image } from 'types/images';
import { useResizeCallback } from 'hooks';

type ButtonsListProps = {
	items: Array<{
		buttonLabel: string;
		image: Image;
		text: string;
	}>;
};

const ButtonsList: FC<ButtonsListProps> = ({ items }) => {
	const [currentIndex, setCurrentIndex] = useState<keyof typeof items>();
	const [textHeight, setTextHeight] = useState<number>();

	const refs = useMemo(
		() => Array.from(items, () => createRef<HTMLDivElement>()),
		[items]
	);

	useResizeCallback(() => {
		const height = refs.reduce((accumulator, ref) => {
			if (ref.current) {
				const textWrap = ref.current.querySelector(
					'.popup-text-wrap'
				) as HTMLDivElement;

				if (textWrap && textWrap.offsetHeight > accumulator) {
				}
				return textWrap.offsetHeight;
			}

			return accumulator;
		}, 0);

		if (height > 0) {
			setTextHeight(height);
		}
	}, [refs]);

	const toggleCurrentItem = (index: number) => {
		const ref = refs[index];

		if (ref.current) {
			const image = ref.current.querySelector('.popup-image');

			const isAbsolute =
				image && window.getComputedStyle(image).position === 'absolute';

			ref.current.style.height = isAbsolute
				? '0'
				: `${ref.current.scrollHeight}px`;
		}

		setCurrentIndex(currentIndex === index ? undefined : index);
	};

	const style = textHeight ? { marginTop: `${textHeight}px` } : undefined;

	return (
		<div className="buttons-list" style={style}>
			{items.map((item, index) => (
				<Fragment key={index}>
					<Button
						onMouseLeave={() => setCurrentIndex(undefined)}
						onMouseOver={() => setCurrentIndex(index)}
						onTouchEnd={() => toggleCurrentItem(index)}
					>
						{item.buttonLabel}
					</Button>
					<div
						className={classnames('popup-content-wrap', {
							active: currentIndex === index,
						})}
						ref={refs[index]}
					>
						<div className="popup-content">
							<img
								className="popup-image"
								src={item.image.src}
								alt={item.image.title}
							/>
							<div className="popup-text-wrap">
								<Text className="popup-text">{item.text}</Text>
							</div>
						</div>
					</div>
				</Fragment>
			))}
		</div>
	);
};

export default ButtonsList;
