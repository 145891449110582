/**
 * External dependencies
 */
import { findKey, mapValues } from 'lodash';

/**
 * Internal dependencies
 */
import { useMatchMedia } from '.';
import * as breakpoints from 'styles/config/_breakpoints.module.scss';

const useBreakpoint = () => {
	const matchMedia = mapValues(breakpoints, (value) =>
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useMatchMedia(`(max-width: ${value})`)
	);

	return findKey(matchMedia, (value) => !!value);
};

export default useBreakpoint;
