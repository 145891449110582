/**
 * External dependencies
 */
import {
	FC,
	PropsWithChildren,
	RefObject,
	useCallback,
	useMemo,
	useState,
} from 'react';

/**
 * Internal dependencies
 */
import { HighlightProps } from './Highlight';
import { isRefObject } from 'utils';
import { useCalculateCursorFollower } from 'hooks/useCalculateCursorFollower';
import {
	CursorFollowerContext,
	CursorFollowerHighlightContext,
	CursorFollowerTargetContext,
} from 'contexts';

type CursorFollowerProviderProps = PropsWithChildren<
	{
		speed?: number;
	} & HighlightProps
>;

/**
 * Component providing information about the cursor and the follower position, target element and functions to
 * set/remove a target element and props for `CursorFollowerHighlight` component.
 */
const CursorFollowerProvider: FC<CursorFollowerProviderProps> = ({
	children,
	matchTargetSize,
	radius,
	speed,
	targetScale,
}) => {
	const [targetElement, setTargetElement] = useState<HTMLElement>();

	/**
	 * Cursor follower position calculated based on the target position if any target element selected, mouse position
	 * otherwise.
	 */
	const { follower } = useCalculateCursorFollower({ speed, targetElement });

	/**
	 * Sets target element. This function accepts a `RefObject` or plain HTML element as a param.
	 */
	const setTargetElementProxy = useCallback(
		(element: HTMLElement | RefObject<HTMLElement>) =>
			setTargetElement(
				isRefObject(element) ? element.current || undefined : element
			),
		[]
	);

	/**
	 * Sets `null` as a target element.
	 */
	const removeTargetElement = useCallback(
		() => setTargetElement(undefined),
		[]
	);

	/**
	 * Memoized value for `CursorFollowerTargetContext`. This constant only changes when the `targetElement` changes to
	 * prevent superflous rerendering of a `CursorFollowerTargetContext.Provider`.
	 */
	const targetContextValue = useMemo(
		() => ({
			removeTargetElement,
			setTargetElement: setTargetElementProxy,
			targetElement,
		}),
		[removeTargetElement, setTargetElementProxy, targetElement]
	);

	/**
	 * Memoized value for `CursorFollowerHighlightContext`. Only changes when the prop values change.
	 */
	const highlightProps = useMemo(
		() => ({
			matchTargetSize,
			radius,
			targetScale,
		}),
		[matchTargetSize, radius, targetScale]
	);

	return (
		<CursorFollowerContext.Provider value={follower}>
			<CursorFollowerTargetContext.Provider value={targetContextValue}>
				<CursorFollowerHighlightContext.Provider value={highlightProps}>
					{children}
				</CursorFollowerHighlightContext.Provider>
			</CursorFollowerTargetContext.Provider>
		</CursorFollowerContext.Provider>
	);
};

export default CursorFollowerProvider;
