export const clamp = (input: number, min: number, max: number): number =>
	Math.max(min, Math.min(max, input));

export const mapRange = (
	current: number,
	inMin: number,
	inMax: number,
	outMin: number,
	outMax: number
): number => {
	const mapped =
		((current - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

	return clamp(mapped, outMin, outMax);
};
