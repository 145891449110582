/**
 * Internal dependencies
 */
import { ActionCreator, Action } from './types';

const isAction = <P>(
	action: Action<any>,
	actionCreator: ActionCreator<P>
): action is Action<P> => action.type === actionCreator.type;

export default isAction;
