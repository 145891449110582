export type CaseStudyTag = {
	[key: string]: string;
};

const config: CaseStudyTag = {
	all: 'All',
	consulting: 'Consulting',
	development: 'Development',
	nda: 'NDA',
};

export default config;
