/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Container, FullWidth } from 'components';
import List from 'components/List';
import './styles.scss';

type CaseStudyHeroProps = {
	params: Record<string, string>;
	title: string;
};

const CaseStudyHero: FC<CaseStudyHeroProps> = ({ params, title }) => (
	<FullWidth className="case-study-hero">
		<Container className="case-study-hero-inner">
			<h1 className="case-study-hero-title is-style-h1-small">{title}</h1>
			<List color="white">
				{Object.entries(params).map(([key, value], index) => (
					<p className="case-study-hero-list-item" key={index}>
						{key}: <span>{value}</span>
					</p>
				))}
			</List>
		</Container>
	</FullWidth>
);

export default CaseStudyHero;
