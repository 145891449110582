/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import { Box, Button, Image } from 'components';
import { Image as ImageType } from 'types';
import './styles.scss';

type ImageBoxProps = {
	buttonText: string;
	image: ImageType;
	subtitle: string;
	title: string;
	to: string;
};

const ImageBox: FC<ImageBoxProps> = ({
	buttonText,
	image,
	subtitle,
	title,
	to,
}) => (
	<Box>
		<h2 className="is-style-h2-small image-box-title">{title}</h2>
		<p className="is-style-p-with-mobile image-box-subtitle">{subtitle}</p>
		<Image
			radius="small"
			image={{
				src: image.src,
				title: image.title,
			}}
		/>
		<Link to={to} className="image-box-link">
			<Button>{buttonText}</Button>
		</Link>
	</Box>
);

export default ImageBox;
