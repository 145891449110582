/**
 * External dependencies
 */
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type FiltersProps<T extends Record<string, string>> = {
	value: keyof T;
	onChange: (value: keyof T) => void;
	items: T;
};

const Filters = <T extends Record<string, string>>({
	value,
	onChange,
	items,
}: FiltersProps<T>) => (
	<div className="filters">
		{Object.entries(items).map(([key, itemValue]) => (
			<button
				onClick={() => onChange(key)}
				key={key}
				className={classnames('filters-item', {
					'is-active': key === value,
				})}
			>
				{itemValue}
			</button>
		))}
	</div>
);

export default Filters;
