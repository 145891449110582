/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { Image } from 'types';
import { ReactComponent as Separator } from 'images/separator.svg';
import './styles.scss';

export type PersonInfoProps = {
	avatar: Image;
	color?: 'white' | 'dark';
	description?: string;
	/**
	 * This component has 2 size variants in mobile design (below `sm` breakpoint).
	 * Use this prop to change the mobile size.
	 *
	 * Note: this prop won't affect the desktop size.
	 */
	mobileSize?: 'normal' | 'small';
	name: string;
	prefix?: string;
	role?: string;
};

const PersonInfo: FC<PersonInfoProps> = ({
	avatar,
	color = 'dark',
	description,
	mobileSize = 'normal',
	name,
	prefix,
	role,
}) => (
	<div
		className={classnames('person-info', `has-${color}-color`, mobileSize)}
	>
		<img
			className="person-info-avatar"
			src={avatar?.src}
			alt={avatar?.title}
		/>
		<div className="persona-info-text-wrap">
			<p className="person-info-text">
				{prefix}
				<span className="person-info-name">{name}</span>
				<div>
					{role && <Separator className="separator" />} {role}
				</div>
			</p>
			{description && (
				<p className="person-info-description">{description}</p>
			)}
		</div>
	</div>
);

export default PersonInfo;
