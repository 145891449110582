import { CaseStudies, ContentWithLink, Logos, NavBarColorSwitcher, Spacer, StatementHero, WhatWeDo, Cta } from "components";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wupwt from "images/client-logos/wupwt.png";
import { graphql } from "gatsby";
import * as React from 'react';
export default {
  CaseStudies,
  ContentWithLink,
  Logos,
  NavBarColorSwitcher,
  Spacer,
  StatementHero,
  WhatWeDo,
  Cta,
  fusebox,
  gpd,
  insanelab,
  thecamels,
  tnbt,
  wpserved,
  wupwt,
  graphql,
  React
};