/**
 * External dependencies
 */
import { findKey } from 'lodash';

/**
 * Internal dependencies
 */
import { useBreakpoint } from '.';
import * as breakpoints from 'styles/config/_breakpoints.module.scss';

const useBreakpointDownValue = <T extends any>(
	values?: Record<string, T>
): T | undefined => {
	const breakpoint = useBreakpoint();

	if (breakpoint && values) {
		const sortedBreakpoints = Object.entries(breakpoints).sort(
			(a, b) => parseInt(a[1]) - parseInt(b[1])
		);

		const key = findKey(
			sortedBreakpoints,
			(item) =>
				Array.isArray(item) &&
				item[1] === breakpoints[breakpoint as keyof typeof breakpoints]
		);

		if (key) {
			for (let i = parseInt(key); i < sortedBreakpoints.length; i++) {
				if (values.hasOwnProperty(sortedBreakpoints[i][0])) {
					return values[sortedBreakpoints[i][0]];
				}
			}
		}
	}
};

export default useBreakpointDownValue;
