import { BackgroundText, ContentWithLink, HomePageHero, Logos, MeetTheTeam, NavBarColorSwitcher, Spacer, Words } from "components";
import planning from "images/team-images/planning.jpg";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import sofa2 from "images/team-images/sofa2.png";
import sofa from "images/team-images/sofa.jpg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wupwt from "images/client-logos/wupwt.png";
import * as React from 'react';
export default {
  BackgroundText,
  ContentWithLink,
  HomePageHero,
  Logos,
  MeetTheTeam,
  NavBarColorSwitcher,
  Spacer,
  Words,
  planning,
  fusebox,
  gpd,
  insanelab,
  sofa2,
  sofa,
  thecamels,
  tnbt,
  wpserved,
  wupwt,
  React
};