/**
 * External dependencies
 */
import { combineReducers, createStore as reduxCreateStore } from 'redux';
import {
	TypedUseSelectorHook,
	useDispatch as useReduxDispatch,
	useSelector as useReduxSelector,
} from 'react-redux';

/**
 * Internal dependencies
 */
import { Dispatch, RootState } from './types';
import * as reducers from './reducers';
import initialState from './initialState';

export const reducer = combineReducers(reducers);

export const createStore = (partialInitialState?: Partial<RootState>) =>
	reduxCreateStore(reducer, {
		...initialState,
		...partialInitialState,
	});

export const useDispatch = () => useReduxDispatch<Dispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export { default as StoreProvider } from './provider';
export * from './action-creators';
export * from './actions';
export * from './type-guards';
