/**
 * External dependencies
 */
import { FC, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import parseHTML from 'html-react-parser';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { Alignment, RequireOnlyOne, TextStyle, TextTag } from 'types';
import { rehypeClassNamePlugin, rehypeStylePlugin } from 'utils';

export type TextProps = RequireOnlyOne<
	{
		/**
		 * Text alignment.
		 */
		align?: Alignment;

		/**
		 * The text. Can be a string with HTML or Markdown markup or any set of React elements.
		 *
		 * @type ReactNode
		 */
		children?: ReactNode;

		/**
		 * Allows to pass additional class name. Using this prop alogn with a markdown syntax will create the wrapping `div`
		 * element with this class attachend.
		 */
		className?: string;

		/**
		 * Text color
		 */
		color?: string;

		/**
		 * This prop can be used instead of a `children`.
		 */
		content?: ReactNode;

		/**
		 * Allows to set different text style. Possible values corresponds to a text styles defined in stylesheet.
		 */
		style?: TextStyle;

		/**
		 * Defines which tag to use for the main element.
		 * This prop does not work with markdown syntax.
		 */
		tag?: TextTag;
	},
	'children' | 'content'
>;

const allowedElements = [
	'strong',
	'em',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'p',
];

const Text: FC<TextProps> = ({
	align,
	children,
	className,
	color,
	style,
	tag: Tag = 'p',
}) => {
	children = typeof children === 'string' ? parseHTML(children) : children;

	className = classnames(className, {
		[`is-style-${style}`]: style,
		[`align-${align}`]: align,
	});

	const textStyles = color ? { color } : undefined;

	if (typeof children === 'string') {
		if (Tag !== 'p' && children.charAt(0) !== '#') {
			children = `${'#'.repeat(parseInt(Tag[1]))} ${children}`;
		}

		return (
			<ReactMarkdown
				{...{ allowedElements }}
				unwrapDisallowed
				rehypePlugins={[
					[rehypeClassNamePlugin, { className }],
					[rehypeStylePlugin, { style: textStyles }],
				]}
			>
				{children as string}
			</ReactMarkdown>
		);
	}

	return <Tag {...{ className, children }} style={textStyles} />;
};

export default Text;
