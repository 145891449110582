/**
 * External dependencies
 */
import { FC, useState } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Exclamation } from 'components';

type WordProps = {
	children: string;
	exclamationColor: string;
	exclamationSize: number;
};

const Word: FC<WordProps> = ({
	children,
	exclamationColor,
	exclamationSize,
}) => {
	const [expanded, setExpanded] = useState(false);
	const toggleExclamation = () => setExpanded(!expanded);

	return (
		<div className="word">
			<span
				onMouseOver={toggleExclamation}
				onMouseOut={toggleExclamation}
			>
				{children}
				<Exclamation
					expanded={expanded}
					color={exclamationColor}
					size={exclamationSize}
				/>
			</span>
		</div>
	);
};

export default Word;
