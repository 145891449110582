/**
 * External dependencies
 */
import { FC, Fragment, ReactNode } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button, Container, FullWidth } from 'components';
import { ButtonProps } from 'components/Button/types';
import { Alignment } from 'types/alignment';

interface HeadingWithButtonsProps {
	/**
	 * An array of objects containing button props
	 */
	buttons: Array<ButtonProps>;
	/**
	 * Text color variant
	 */
	variant?: 'dark' | 'light';
	/**
	 * Text displayed under the title
	 */
	description: string;
	/**
	 * Optional separator placed between the buttons (if more than one)
	 */
	separator?: ReactNode;
	/**
	 * Text alignment. This will affect both the title and the description.
	 */
	textAlign?: Alignment;
	/**
	 * The title dislayed as a large text at the top
	 */
	title: string;
}

const HeadingWithButtons: FC<HeadingWithButtonsProps> = ({
	buttons,
	variant = 'dark',
	description,
	separator,
	textAlign = 'left',
	title,
}) => (
	<FullWidth
		className={classnames(
			'heading-with-buttons',
			`align-${textAlign}`,
			variant
		)}
	>
		<Container>
			<div className="inner">
				<h1 className="title">{title}</h1>
				<p className="description">{description}</p>
				<div className="buttons">
					{buttons.map((button, index) => (
						<Fragment key={index}>
							{separator && index > 0 && (
								<span className="button-separator">
									{separator}
								</span>
							)}
							<Button {...button} />
						</Fragment>
					))}
				</div>
			</div>
		</Container>
	</FullWidth>
);

export default HeadingWithButtons;
