import { PageProps } from 'gatsby';
import { ArticleSummary } from '../../index';
import { getImage } from 'gatsby-plugin-image';
import { team } from 'config';
import Spacer from '../Spacer';
import { BlogProps } from '../../../templates/blog-template';

export default function BlogPosts({ props }: { props: PageProps<BlogProps> }) {
	return (
		<>
			{props.data.allMdx.edges.map((post, key) => {
				const image = getImage(
					post.node.frontmatter.image.childImageSharp?.gatsbyImageData
				);

				const author = team.find(
					(member) => member.id === post.node.frontmatter.author
				);

				return (
					<div key={key}>
						<ArticleSummary
							title={post.node.frontmatter.title}
							summary={post.node.frontmatter.summary}
							author={{
								name: author?.name ?? '',
								role: author?.position ?? '',
								avatar: {
									src: author?.avatar ?? '',
									title: author?.name ?? '',
								},
							}}
							poster={{
								src: image?.images.fallback?.src ?? '',
								title: post.node.frontmatter.image_title,
							}}
							url={post.node.frontmatter.slug}
						/>
						<Spacer height={100} responsive={{ md: 80, sm: 60 }} />
					</div>
				);
			})}
		</>
	);
}
