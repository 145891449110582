/**
 * External dependencies
 */
import { FC, forwardRef, Ref, HTMLAttributes } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type FullWidthProps = {
	ref?: Ref<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

const FullWidth: FC<FullWidthProps> = forwardRef<
	HTMLDivElement,
	FullWidthProps
>(({ children, className, ...props }, ref) => (
	<div className={classnames(className, 'fullwidth')} ref={ref} {...props}>
		{children}
	</div>
));

export default FullWidth;
