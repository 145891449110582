/**
 * External dependencies
 */
import {
	createRef,
	FC,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { debounce } from 'lodash';
import { Link } from 'gatsby';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { AnchorAttributes, ButtonProps, RefsArray, StylesArray } from './types';

const Button: FC<ButtonProps> = forwardRef<HTMLDivElement, ButtonProps>(
	(
		{
			animateHoverText = true,
			children,
			className: baseClassName,
			color = 'dark',
			hoverText,
			to,
			viewportRelative = false,
			...restProps
		},
		forwardedRef
	) => {
		const refs: RefsArray = useMemo(
			() => [createRef<HTMLSpanElement>(), createRef<HTMLSpanElement>()],
			[]
		);

		const [styles, setStyles] = useState<StylesArray>([
			undefined,
			undefined,
			undefined,
		]);

		const setStylesCallback = useCallback(() => {
			if (!refs.every((ref) => ref.current)) {
				return;
			}

			const newStyles = [];
			const widths = [];

			for (const ref of refs) {
				newStyles.push({
					maxWidth: `${ref.current!.scrollWidth}px`,
				});

				widths.push(ref.current!.scrollWidth);
			}

			newStyles.push(
				widths[1] > widths[0]
					? {
							paddingRight: `${widths[1] - widths[0]}px`,
					  }
					: undefined
			);

			setStyles(newStyles as StylesArray);
			/* eslint-disable-next-line react-hooks/exhaustive-deps */
		}, [children, hoverText, refs]);

		useEffect(() => {
			setStylesCallback();

			const debouncedCallback = debounce(setStylesCallback);

			window.addEventListener('resize', debouncedCallback);

			return () =>
				window.removeEventListener('resize', debouncedCallback);
		}, [setStylesCallback]);

		const Element = to
			? to.substring(0, 1) === '/'
				? Link
				: 'a'
			: 'button';

		const className = classnames(
			baseClassName,
			'btn',
			`has-${color}-color`,
			{
				'animate-hover-text': animateHoverText,
				'has-hover-text': hoverText,
				'viewport-relative': viewportRelative,
			}
		);

		if (to) {
			if (Element === 'a') {
				(restProps as AnchorAttributes).href = to;
			} else {
				(restProps as ButtonProps).to = to;
			}
		}

		return (
			<Element
				className={className}
				ref={forwardedRef}
				{...(restProps as any)}
			>
				<span className="btn-wrap" style={styles[2]}>
					<span className="btn-content">
						{hoverText && (
							<span
								className="btn-label"
								ref={refs[0]}
								style={styles[0]}
							>
								{hoverText}
							</span>
						)}
						<span
							className="btn-label"
							ref={refs[1]}
							style={styles[1]}
						>
							{children}
						</span>
					</span>
				</span>
			</Element>
		);
	}
);

export default Button;
