/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { pxToRem } from 'utils';
import { useBreakpointDownValue, useIsMounted } from 'hooks';

type SpacerProps = {
	height: number;
	responsive?: Record<string, number>;
};

const Spacer: FC<SpacerProps> = ({ height, responsive }) => {
	const responsiveValue = useBreakpointDownValue(responsive);

	if (!useIsMounted()) {
		return null;
	}

	if (responsiveValue) {
		height = responsiveValue;
	}

	return (
		<div className="spacer" style={{ height: `${pxToRem(height)}rem` }} />
	);
};

export default Spacer;
