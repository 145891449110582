/**
 * External dependencies
 */
import { find } from 'lodash';

export const findNode = <N extends any>(
	name: string,
	data: { nodes: Array<{ [key: typeof name]: N }> }
): N | undefined => {
	if (!data.nodes) {
		return;
	}

	const foundNode = find(data.nodes, (node) => node[name]);

	if (typeof foundNode === 'object') {
		return foundNode[name];
	}
};
