/**
 * External dependencies
 */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { debounce, merge } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import defaultSliderSettings from './sliderSettings';
import { People } from './types';
import { NextArrow, PrevArrow } from './Arrow';

type TeamProps = {
	people: People;
	sliderSettings?: Settings;
};

const Team: FC<TeamProps> = ({ people, sliderSettings }) => {
	const [imageHeight, setImageHeight] = useState<number>(0);
	const [isSwiping, setIsSwiping] = useState<boolean>(false);

	const slider = useRef<Slider>(null);

	const setHeight = useCallback(() => {
		if (slider.current) {
			const list = (slider.current as any).innerSlider
				.list as HTMLDivElement;

			const imageWrap = list.querySelector(
				'.slick-center .team-member-image-wrap'
			);

			if (imageWrap) {
				setImageHeight(imageWrap.clientHeight);
			}
		}
	}, [slider]);

	useEffect(() => {
		setTimeout(setHeight, 0);

		const debouncedSetHeight = debounce(setHeight);

		window.addEventListener('resize', debouncedSetHeight);

		return () => window.removeEventListener('resize', debouncedSetHeight);
	}, [setHeight]);

	return (
		<div
			className={classnames('team', { 'is-swiping': isSwiping })}
			onTouchEnd={() => setIsSwiping(false)}
			onMouseUp={() => setIsSwiping(false)}
			style={{ '--team-image-height': `${imageHeight}px` }}
		>
			<Slider
				{...merge(defaultSliderSettings, sliderSettings)}
				prevArrow={<PrevArrow people={people} />}
				nextArrow={<NextArrow people={people} />}
				swipeEvent={() => setIsSwiping(true)}
				onSwipe={() => setIsSwiping(false)}
				afterChange={setHeight}
				ref={slider}
			>
				{people.map(({ name, position, image }) => (
					<div className="team-member" key={name}>
						<div className="team-member-image-wrap">
							<img
								className="team-member-image"
								src={image}
								alt={name}
							/>
						</div>
						<div className="team-member-info">
							<p className="team-member-name">{name}</p>
							<p className="team-member-position">{position}</p>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default Team;
