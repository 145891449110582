/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { TextProps } from '../types';

const Text: FC<TextProps> = ({ content }) => {
	return <p>{content}</p>;
};

export default Text;
