/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button } from 'components';
import { ButtonProps } from 'components/Button/types';
import { Alignment } from 'types';
import { Image } from 'types/images';
import { Text } from 'components';

interface ContentWithLinkProps {
	/**
	 * These are the specs of the button
	 */
	button: ButtonProps;
	/**
	 * This is a text displayed beneath the headline. You can use HTML or Markdown murkup.
	 */
	content: string;
	/**
	 * This is a headline text. You can use HTML or Markdown murkup.
	 */
	headline: string;
	/**
	 * This is how  you want to align the headline text.
	 */
	headlineAlign?: Alignment;
	/**
	 * This is the image that will be displayed.
	 */
	image?: Image;
	/**
	 * This is the image position where you can want it to appear.
	 */
	imagePosition?: 'top' | 'right';
}

const ContentWithLink: FC<ContentWithLinkProps> = ({
	button,
	content,
	headline,
	headlineAlign,
	image,
	imagePosition,
}) => (
	<div
		className={classnames('content-with-link', {
			'has-image': image,
			[`has-image-${imagePosition}`]: image,
		})}
	>
		<div className="header">
			{image && (
				<img src={image.src} alt={image.title} className="image" />
			)}
			<Text className={classnames('title', `align-${headlineAlign}`)}>
				{headline}
			</Text>
		</div>
		<div className="content">
			<Text className="subtitle">{content}</Text>
			<Button {...button} />
		</div>
	</div>
);

export default ContentWithLink;
