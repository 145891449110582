/**
 * External dependencies
 */
import { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import { useCombinedRefs, useResizableEffect, useMatchMedia } from 'hooks';
import * as bps from 'styles/config/_breakpoints.module.scss';

export type LayerProps = {
	/**
	 * Background text decorator path.
	 */
	backgroundPath?: ReactNode;

	/**
	 * All font sizes (and corresponding margins) will be multiplied by this value.
	 */
	fontSizeFactor?: number;

	/**
	 * Two main lines of text.
	 */
	lines: [string, string];

	/**
	 * List of text items displayed beneath the headline.
	 */
	list: Array<string>;

	/**
	 * Second main line's left offset (used to align the back layer's text to front layer's text).
	 */
	offset?: number;

	/**
	 * Callback called when the SVG dimmentions are calculated.
	 */
	onReady?: () => any;

	/**
	 * :List punctator color.
	 */
	punctatorColor?: string;
};

const Layer = forwardRef<SVGSVGElement, LayerProps>(
	(
		{
			backgroundPath,
			fontSizeFactor = 1,
			lines,
			list,
			offset = 0,
			onReady,
			punctatorColor,
		},
		forwardedRef
	) => {
		const baseOffsetTop = 281;
		const minOffsetTop = 120;

		const [viewBoxHeight, setViewBoxHeight] = useState<number>(0);
		const [viewBoxWidth, setViewBoxWidth] = useState<number>(0);
		const [offsetTop, setOffsetTop] = useState<number>(0);
		const [offsetLeft, setOffsetLeft] = useState<number>(0);

		const ref = useCombinedRefs(forwardedRef);

		const isSmallScreen = useMatchMedia(`(max-width: ${(bps as any).sm})`);

		const getVal = useCallback(
			(value: number) => fontSizeFactor * value,
			[fontSizeFactor]
		);

		useEffect(() => {
			if (ref.current) {
				const { height, y } = ref.current.getBBox();

				setViewBoxHeight(height + y + getVal(138));
			}
		}, [getVal, offsetTop, ref]);

		useResizableEffect(() => {
			if (ref.current) {
				const { width } = ref.current.getBoundingClientRect();

				const newViewBoxWidth = Math.max(width, 1420);

				const oLeft = isSmallScreen
					? (20 / width) * 1420
					: width < 1500
					? (40 / width) * 1420
					: (width - 1420) / 2;

				const scale = width / newViewBoxWidth;

				const oTop =
					scale * baseOffsetTop < minOffsetTop
						? minOffsetTop / scale - baseOffsetTop
						: 0;

				setOffsetLeft(oLeft);
				setOffsetTop(oTop);
				setViewBoxWidth(newViewBoxWidth);
			}
		}, [ref, isSmallScreen]);

		useEffect(() => {
			if (viewBoxHeight && onReady) {
				onReady();
			}
		}, [onReady, viewBoxHeight]);

		const getText = (text: string, size: number, x: number, y: number) => (
			<text
				style={{
					fontSize: `${getVal(size)}px`,
				}}
				x={x}
				y={getVal(y)}
			>
				{text}
			</text>
		);

		const listStyle = {
			fontFamily: 'sofia-pro',
			fontSize: `${getVal(80)}px`,
			fontWeight: 800,
		};

		const getList = () => (
			<g style={listStyle}>
				{list.map((item, index) => (
					<g key={index}>
						<path
							d="M35.5319 0H44.373V44H0.373047V34.8599L35.5319 0Z"
							fill={punctatorColor}
							transform={`translate(100, ${551 + index * 110})`}
						/>
						<text x={getVal(165)} y={getVal(595 + index * 110)}>
							{item}
						</text>
					</g>
				))}
			</g>
		);

		const svgStyle = {
			fontFamily: 'MontHeavy',
			fill: '#fff',
		};

		return (
			<svg
				className="layer"
				ref={ref}
				style={svgStyle}
				viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
				width="100%"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g transform={`translate(${offsetLeft}, ${offsetTop})`}>
					{backgroundPath && (
						<>
							<g
								transform={`translate(${getVal(-251)}, ${getVal(
									157
								)}) scale(${fontSizeFactor})`}
							>
								{backgroundPath}
							</g>
							<g
								transform={`translate(${getVal(301)}, ${
									viewBoxHeight - getVal(349) - offsetTop
								}) scale(${fontSizeFactor})`}
							>
								{backgroundPath}
							</g>
						</>
					)}
					{getText(lines[0], 100, 0, 281)}
					{getText(lines[1], 150, offset, 443)}
					{getList()}
				</g>
			</svg>
		);
	}
);

export default Layer;
