/**
 * External dependencies
 */
import { DependencyList, EffectCallback, useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import FontFaceObserver from 'fontfaceobserver';

const useFontEffect = (
	callback: EffectCallback,
	fonts: Array<string>,
	deps?: DependencyList
) => {
	const [loaded, setLoaded] = useState<boolean>(false);

	if (!deps) {
		deps = [];
	}

	useEffect(() => {
		(async () => {
			try {
				const promises = [];

				for (const font of fonts) {
					const ffo = new FontFaceObserver(font);
					promises.push(ffo.load(null, 5000));
				}

				await Promise.all(promises);

				setLoaded(true);
			} catch (err) {
				window.location.reload();
			}
		})();

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, fonts);

	useEffect(() => {
		loaded && callback();
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [loaded, ...deps]);
};

export default useFontEffect;
