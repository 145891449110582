/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as Facebook } from 'images/facebook.svg';
import { SocialMediaShare } from '../types';
import ShareButton from 'components/ShareButtons/ShareButton';

const FacebookShareButton: FC<SocialMediaShare> = ({ href }) => (
	<ShareButton
		href={`https://www.facebook.com/sharer/sharer.php?u=${href}`}
		icon={<Facebook />}
	/>
);

export default FacebookShareButton;
