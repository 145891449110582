import { useState } from 'react';
import { Filters, GridItem } from '../../index';
import Grid from '../../Grid';
import { getImage } from 'gatsby-plugin-image';
import './styles.scss';
import { caseStudiesTags } from 'config';
import { PageProps } from 'gatsby';

type CaseStudyProps = {
	mdx: {
		frontmatter: {
			title: string;
		};
		body: string;
	};
};

const CaseStudies = ({ props }: { props: PageProps<CaseStudyProps> }) => {
	const [selectedTag, setSelectedTag] =
		useState<keyof typeof caseStudiesTags>('all');

	return (
		<div className="case-studies">
			<div>
				<h4 className="case-studies-title">Case Studies</h4>
				<div className="case-studies-filters">
					<Filters
						onChange={(value) => setSelectedTag(value)}
						items={caseStudiesTags}
						value={selectedTag}
					/>
				</div>
			</div>
			<Grid>
				{props.data?.allMdx?.edges
					.filter((study) => {
						if (selectedTag === 'all') {
							return true;
						}

						if (study.node.frontmatter.tags.includes(selectedTag)) {
							return true;
						}

						return false;
					})
					.map((study, key) => {
						const image = getImage(
							study.node.frontmatter.image.childImageSharp
								?.gatsbyImageData
						);

						return (
							<GridItem
								key={key}
								title={study.node.frontmatter.title}
								link={study.node.frontmatter.slug}
								tagline={study.node.frontmatter.tagline}
								image={{
									image: image,
									title: study.node.frontmatter.image_title,
								}}
							/>
						);
					})}
			</Grid>
		</div>
	);
};

export default CaseStudies;
