/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { Box, FlexList } from 'components';
import './styles.scss';

type TabletsBoxProps = PropsWithChildren<{
	items: Array<string>;
	title: string;
}>;

const TabletsBox: FC<TabletsBoxProps> = ({ items, title }) => (
	<Box>
		<h2 className="tablets-box-title is-style-h2-small">{title}</h2>
		<FlexList items={items} />
	</Box>
);

export default TabletsBox;
