/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type BoxProps = PropsWithChildren<{
	type?: 'primary' | 'secondary' | 'tertiary';
}>;

const Box: FC<BoxProps> = ({ children, type = 'primary' }) => (
	<div className={classnames('box', type)}>
		<div className="box-content">{children}</div>
	</div>
);

export default Box;
