/**
 * External dependencies
 */
import { FC, HTMLAttributes } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { FullWidth } from 'components';
import { LeftOrRight } from 'types/alignment';
import { useBreakpointDownValue, useIsMounted } from 'hooks';

type BackgroundTextBaseProps = {
	offsetX?: number;
	offsetY?: number;
	position?: LeftOrRight;
	textAlign?: LeftOrRight;
};

type BackgroundTextProps = BackgroundTextBaseProps & {
	children: string;
	responsive?: Record<string, BackgroundTextBaseProps>;
} & HTMLAttributes<HTMLDivElement>;

const BackgroundText: FC<BackgroundTextProps> = ({
	children,
	className,
	offsetX = 0,
	offsetY = 0,
	position = 'left',
	responsive,
	textAlign,
	...props
}) => {
	const responsiveValue = useBreakpointDownValue(responsive);

	if (!useIsMounted()) {
		return null;
	}

	if (!textAlign) {
		textAlign = position;
	}

	if (responsiveValue) {
		if (responsiveValue.offsetX) {
			offsetX = responsiveValue.offsetX!;
		}

		if (responsiveValue.offsetY) {
			offsetY = responsiveValue.offsetY!;
		}

		if (responsiveValue.position) {
			position = responsiveValue.position!;
		}

		if (responsiveValue.textAlign) {
			textAlign = responsiveValue.textAlign!;
		}
	}

	const paragraphClassName = classnames(
		`position-${position}`,
		`align-${textAlign}`
	);

	const pStyle = offsetX
		? {
				transform: `translateX(${offsetX}px)`,
		  }
		: undefined;

	const wrapStyle = offsetY
		? {
				transform: `translateY(${offsetY}px)`,
		  }
		: undefined;

	return (
		<FullWidth
			className={classnames(className, 'background-text')}
			{...props}
		>
			<div className="background-text--inner" style={wrapStyle}>
				<p className={paragraphClassName} style={pStyle}>
					{children.split(' ').map((word, index) => (
						<span key={index}>{word}</span>
					))}
				</p>
			</div>
		</FullWidth>
	);
};

export default BackgroundText;
