/**
 * Internal dependencies
 */
import { ListType, ObjectList, StringList } from './types';

export const isPlainList = (items: ListType): items is StringList =>
	!!items.length && typeof items[0] === 'string';

export const isButtonsList = (items: ListType): items is ObjectList =>
	!!items.length && typeof items[0] === 'object';
