/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ReactComponent as Decorator } from 'images/decorator-white.svg';

type CopyrightProps = {
	children?: string;
	color?: 'white' | 'dark';
};

const Copyright: FC<CopyrightProps> = ({
	children = '©%year%',
	color = 'white',
}) => (
	<div className={classnames('copyright', `copyright-${color}`)}>
		<Decorator />
		<span className="copyright-year">
			{children.replaceAll('%year%', new Date().getFullYear().toString())}
		</span>
	</div>
);

export default Copyright;
