/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import { CursorFollowerContext } from 'contexts';

const useCursorFollowerPosition = () => useContext(CursorFollowerContext);

export default useCursorFollowerPosition;
