/**
 * External dependencies
 */
import {
	cloneElement,
	FC,
	isValidElement,
	PropsWithChildren,
	useRef,
} from 'react';

/**
 * Internal dependencies
 */
import { useCursorFollowerTarget } from 'hooks';

const CursorFollowerTarget: FC<PropsWithChildren> = ({ children }) => {
	const ref = useRef(null);
	const { setTargetElement, removeTargetElement } = useCursorFollowerTarget();

	if (!isValidElement(children)) {
		return null;
	}

	const onMouseOver = () => {
		setTargetElement(ref);
		children.props.onMouseOver && children.props.onMouseOver();
	};

	const onMouseOut = () => {
		removeTargetElement();
		children.props.onMouseOut && children.props.onMouseOut();
	};

	return cloneElement<any>(children, {
		onMouseOut,
		onMouseOver,
		ref,
	});
};

export default CursorFollowerTarget;
