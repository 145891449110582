/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import { CursorFollowerTargetContext } from 'contexts';

const useCursorFollowerTarget = () => useContext(CursorFollowerTargetContext);

export default useCursorFollowerTarget;
